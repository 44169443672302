import React, {useState, useCallback} from "react";
import { toast } from "react-toastify";
import numeral from "numeral";
import moment from "moment";
import BigNumber from "bignumber.js";
import { contractConfig } from "../../config/contract";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

function UnclaimedItem({ unclaimedItem }) {
  const [loading, setLoading] = useState(false);
  const [retried, setRetried] = useState(false);
  const { account } = useWeb3React();
  const claimEvent = useCallback(async() => {
    try {
      if (loading) {
        return true;
      }
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(contractConfig.gameContract.address, contractConfig.gameContract.abi, signer);
      const shfContract = new ethers.Contract(contractConfig.shfContract.address, contractConfig.shfContract.abi, signer);
      // Recheck the balance 
      let SHFbalance = await shfContract.balanceOf(await signer.getAddress());
      // convert response balance to same standard
      // If network error the error message will show instead wrong message
      SHFbalance = new BigNumber(SHFbalance.toString());
      
      let amount  = new BigNumber(unclaimedItem.amount);
      let amountWithDecimal = amount.multipliedBy(1000000000);
      //Log the amount 
      console.log("Amount : ",SHFbalance.toString(),amountWithDecimal.toString());
      //Validate the balance 
      if (SHFbalance.isLessThan(amountWithDecimal)) {
        toast("Insufficient SFT");
        setLoading(false);
        return false;
      }

      const approve = await shfContract.approve(contractConfig.gameContract.address, amountWithDecimal.toString());  
      if(!approve.hash){
        console.log("can-not-approve",approve);
        toast("Can not approve this transaction. please retry again later!");
        setLoading(false);
        // ? disable popup?
      }
      console.log("now wait for approve")
      await provider.waitForTransaction(approve.hash);
      console.log("approved");

      const claim = await contract.claimDiamond(unclaimedItem.id, amountWithDecimal.toString(), unclaimedItem.rate, unclaimedItem.signature);
      console.log("now-wating-for-claiming-confirmation");
      
      await provider.waitForTransaction(claim.hash);
      console.log("claim-confirmed");
      // Complete the function 
      toast("Claim retry success!");
      setLoading(false);
      setRetried(true);
      window.$("#js-unclaimedPopup").modal("hide");

    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [unclaimedItem, loading, account])

  return (
    <>
      {!retried && 
      <div className="row stake-plan-item user-stake-item">
        <div className="col-lg-2"><img src="/assets/images/Shibafriend-Diamond.png" className="stake-plan-icon-small" /></div>
        <div className="col-lg-4">
          <div className="stake-plan-head">DIAMOND EARNED</div>
          <div className="stake-plan">{numeral(unclaimedItem.amount).format("0,0.00000")}</div>
        </div>
        <div className='col-lg-3'>
          <div className="stake-plan-head">DATE</div>
          <div className="stake-plan">{moment(unclaimedItem.created_at).format("DD/MM/YYYY hh::mm")}</div>
        </div>
        <div className="col-lg-3">
          <div className="stake-plan"><a className="mybtn1" onClick={() => claimEvent(unclaimedItem)}>{loading ? "Loading..." : "Retry"}</a></div>
        </div>
      </div>
      }
    </>
  );
}

export default UnclaimedItem;
