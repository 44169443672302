import React from "react";
import { ToastContainer } from "react-toastify";
import HeaderForDashboard from "../../components/HeaderForDashboard";
import AirdropDiamond from "../../components/AirdropDiamond";
import WalletListPopup from "../../components/WalletListPopup";

function AirdropDiamondIndex(props) {
  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"marketplace"}/>
      <AirdropDiamond/>
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <WalletListPopup />
    </div>
  );
}

export default AirdropDiamondIndex;
