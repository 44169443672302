import React, {useMemo} from "react";
import numeral from "numeral";

import { currencies } from "../../config/currency";

function NftDetail({ nftItem }) {
  const currentCurrency = useMemo(
    () => currencies.find((u) => u.address.toLowerCase() === nftItem?.currency?.toLowerCase()),
    [nftItem?.currency]
  );

  return (
    <div className="c-nftItem">
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem?.name}</div>
        <div className="c-nftItem__image">
          <img src={nftItem?.image} />
        </div>
        <div className="c-nftItem__batchName">
          {nftItem?.batch_name}
        </div>
        <div className="c-nftItem__tier">
          {nftItem?.tier}
        </div>
        {nftItem?.price && 
        <div className="c-nftItem__buttons">
          <span className="c-nftItem__price">${numeral(nftItem?.price).format("0,0")}</span>
          <span className="c-nftItem__currency">{currentCurrency?.text}</span>
        </div>
        }
      </div>
    </div>
  );
}

export default NftDetail;
