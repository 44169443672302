import React, {useState, useMemo, useCallback, useEffect} from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

import { apiMarketUrl } from "../../hooks/useFetchOption";
import useAuthenticatedSwr from "../../hooks/useAuthenticatedSwr";
import { gameToken } from "../../config/global";
import { contractConfig } from "../../config/contract";

import NftItem from "./NftItem";
import ConnectWallet from "../ConnectWallet";

function MarketplaceIndex() {
  const { account } = useWeb3React();
  const accessToken = window.localStorage.getItem(gameToken);
  const [allNFTIds, setAllNFTIds] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [requestParams, setRequestParams] = useState({
    page: 1,
    per_page: 30,
    "designs[]": [],
    keyword: "",
  });

  const handlePageClick = useCallback((event) => {
    setRequestParams((prev) => ({
      ...prev,
      page: event.selected + 1,
    }));
  }, []);

  const selectDesignEvent = useCallback((event) => {
    let designs = requestParams["designs[]"];
    if (event.target.checked) {
      designs.push(event.target.value);
    } else {
      designs.splice(designs.indexOf(event.target.value), 1);
    }
    setRequestParams((prev) => ({
      ...prev,
      "designs[]": designs
    }));
  }, []);

  const endpoint = useMemo(
    () => `/public/houses`,
    [requestParams]
  );

  const endpointDesign = useMemo(
    () => "/public/designs?per_page=100",
    []
  );

  const { data: nftAssets, error } = useAuthenticatedSwr(endpoint, { headers: { Authorization: `Bearer ${accessToken}`,} }, apiMarketUrl);
  const { data: designs, designError } = useAuthenticatedSwr(endpointDesign, {}, apiMarketUrl);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  useEffect(async () => {
    try {
      if (account) {
        const [,ethers,signer] = await initProvider();
        const petContract = new ethers.Contract(contractConfig.petContract.address, contractConfig.petContract.abi, signer);
        let nftIds = await petContract.getAllNFTID();
        setAllNFTIds(nftIds);
        let accounts = Array.from({length: 25}, () => {
          return account;
        });
        console.log(accounts);
        console.log(nftIds);
        let pets = await petContract.balanceOfBatch(accounts, nftIds);
        let nftsTmp = [];
        pets.forEach((numberOfTokens, index) => {
          if (parseInt(numberOfTokens.toString()) > 0) {
            for (var i=1;i<=parseInt(numberOfTokens.toString()); i++) {
              nftsTmp.push(nftIds[index]);
            }
          }
        });
        setNfts(nftsTmp);
      }
    } catch (error) {
      console.log(error);
    }
  }, [account]);

  const nftItemFor = useCallback(
    (id) => {
      let nftItems = [];
      if (nftAssets) {
        nftItems = [...nftAssets?.houses, ...nftAssets?.pets];
      }
      return (nftItems || []).find((item) => item._decimal.toString() === id.toString());
    },
    [nftAssets]
  );

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              { account ?
                <div className="main-box wallet-box">
                  <div className="header-area">
                    <h4>NFT ASSETS</h4>
                  </div>
                  <div className="wallet-tab-menu">
                    <ul className="nav" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="pills-wt1-tab" data-toggle="pill" href="#pills-wt1" role="tab" aria-controls="pills-wt1">PETs</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="pills-wt2-tab" data-toggle="pill" href="#pills-wt2" role="tab" aria-controls="pills-wt2">DOG HOUSES</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <span className="tag">SOON</span>
                        <a className="nav-link" id="pills-wt3-tab" data-toggle="pill" href="#pills-wt3" role="tab" aria-controls="pills-wt3">Home Buildings</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <span className="tag">SOON</span>
                        <a className="nav-link" id="pills-wt3-tab" data-toggle="pill" href="#pills-wt3" role="tab" aria-controls="pills-wt3">Commerial Buildings</a>
                      </li>
                    </ul>
                  </div>
                  <div className="wallet-tab-content"  id="pills-tabContent">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                        <div className="dipo-box">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="row c-filter c-filter__left">
                                Filter <a href="/nft-game-assets" className="c-filter__btnReset">Reset</a>
                              </div>
                              <div className="row c-filter c-filter__list">
                                <div className="accordion c-filterItem" id="designFilter">
                                  <div className="card">
                                    <div className="card-header" id="headingTwo">
                                      <button className="btn btn-link collapsed accordion-button" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Pets
                                      </button>
                                    </div>
                                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#designFilter">
                                      <div className="card-body c-filter__designs">
                                        <div className="search-area">
                                          <form action="#">
                                            <input type="text" placeholder="Search" onChange={(e) => setRequestParams((prev) => ({...prev, keyword: e.target.value}))}/>
                                            <button type="submit" onClick={(event) => event.preventDefault()}><i className="fas fa-search"></i></button>
                                          </form>
                                        </div>
                                        {(nftAssets?.pets || []).map((design, index) =>
                                          <div className="c-designItem custom-checkbox" key={index}>
                                            <input type="checkbox" id={index} className="custom-control-input" value={design.id} onChange={selectDesignEvent}/>
                                            <label htmlFor={index} className="custom-control-label">{design.name}</label>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-9 plan-list c-nftList">
                              <div className="row">
                                {nfts.length ?
                                  (nfts || []).map((nftId, index) =>
                                    nftItemFor(nftId) && <NftItem key={index} nftItem={nftItemFor(nftId)} />
                                  ) : <div className="not-found">No Record</div>}
                              </div>
                              {nfts?.total > 30 &&
                              <div className="c-pagination">
                                <ReactPaginate pageCount={(nfts?.total || 1) / 30} onPageChange={handlePageClick} pageRangeDisplayed={2} marginPagesDisplayed={1} breakLabel='...' forcePage={requestParams?.page - 1}/>
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <ConnectWallet />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MarketplaceIndex;
