import React, {useCallback} from "react";
import "@google/model-viewer";

function NftDetail({ nftItem, isDefault }) {
  console.log(nftItem);
  const attributes = useCallback(
    (attrName) => {
      return (nftItem?.attributes || []).find((item) => item.trait_type.toString() === attrName)?.value;
    },
    [nftItem, nftItem?.attributes]
  );

  return (
    <div className="c-nftItem">
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem?.name}</div>
        <div className="c-nftItem__image">
          <model-viewer class="marketplace-viewer" src={nftItem?.animation_url} camera-controls shadow-intensity="1">
            <div className="lazy-load-poster" slot="poster">Loading...</div>
          </model-viewer>
        </div>
        <div className="c-nftItem__batchName">
          {attributes('skills')}
        </div>
        <div className="c-nftItem__tier">
          {attributes('gender')}
        </div>
      </div>
    </div>
  );
}

export default NftDetail;
