import React, {useState} from "react";
import { ToastContainer } from "react-toastify";
import "../../assets/css/marketplace.css";

import HeaderForDashboard from "../../components/HeaderForDashboard";
import NftStoreIndex from "../../components/NftStore";
import WalletListPopup from "../../components/WalletListPopup";

function Marketplace(props) {
  return (
    <div>
      <HeaderForDashboard currentRoute={"marketplace"} {...props}/>
      <NftStoreIndex />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default Marketplace;
