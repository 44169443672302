function ConnectWallet() {  
  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box connect-wallet">
                <div className="connect-wallet-label">Please connect wallet.</div>
                <a className="custom-btn-v3" onClick={(event) => {
                  event.preventDefault();
                  window.$("#js-walletPopup").modal("show");
                }}>CONNECT WALLET</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConnectWallet;
