import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import {
  NavLink
} from "react-router-dom";
import {isMobile} from "react-device-detect";
import { contractConfig } from "../config/contract";

function HeaderForDashboard(props) {
  const { currentRoute } = props;
  const { signout } = useAuth();
  const { account } = useWeb3React();
  const address = account;

  // useEffect(() => {
  //   const bscChainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
  //   if (account && chainId && bscChainId !== chainId) {
  //     switchNetwork(bscChainId);
  //   }
  // }, [account, chainId]);

  const tokenInfo = {
    diamond: {
      address: contractConfig.diamondContract.address,
      symbol: "DIAMOND",
      decimals: 0,
      image: "https://shibafriend.io/assets/images/Shibafriend-Diamond.png",
    },
    sft: {
      address: "0x01B6D39746ccE500B70cfB242dCC5F7596b11e41",
      symbol: "SFT",
      decimals: 9,
      image: "https://shibafriend.io/img/shibacoin-gold2-1050.png"
    },
    shibanft: {
      address: contractConfig.petContract.address,
      symbol: "SHIBANFT",
      decimals: 0,
      image: "https://shibafriend.io/img/shibacoin-gold2-1050.png",
    }
  };

  async function addToken(event, tokenSymbol) {
    event.preventDefault();
    let options = tokenInfo[tokenSymbol];
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: options,
        },
      });
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  function formatAccount() {
    return address?.slice(0, 5) + "..." + address?.slice(address.length - 4, address.length);
  }

  return (
    <header className="header">
    	<div className="usermenu-section mainmenu-area mainmenu-area2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="usermenu-inner">
                <div className="left-area">
                  <div className="navbar navbar-expand-lg navbar-light">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
                      aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse fixed-height" id="main_menu">
                      <div className="main-menu-inner">
                        <ul className="navbar-nav mr-auto">
                          <li className="usermenu-item">
                            <NavLink to="/dashboard" className={currentRoute === "dashboard" ? "active" : ""}>Overview</NavLink>
                          </li>
                          <li className="usermenu-item">
                            <NavLink to="/token" className={currentRoute === "token" ? "active" : ""}>TOKEN</NavLink>
                          </li>
                          <li className="usermenu-item">
                            <NavLink to="/sftstaking" className={currentRoute === "sftstaking" ? "active" : ""}>SFT STAKING</NavLink>
                          </li>
                          <li className="usermenu-item">
                            <span className="tag">SOON</span>
                            <a href="#">NFT STAKING</a>
                          </li>
                          <li className="usermenu-item">
                            <a href="https://opensea.io/collection/shibafriend-nft" className={currentRoute === "marketplace" ? "active" : ""}>NFT MARKETPLACE</a>
                          </li>
                          <li className="usermenu-item">
                            <NavLink to="/nft-game-assets" className={currentRoute === "nft-game-assets" ? "active" : ""}>NFT ASSETS</NavLink>
                          </li>
                          <li className="usermenu-item">
                            <NavLink to="/play-game" className={currentRoute === "play-game" ? "active" : ""}>PLAY GAME</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-area">
                  {address ?
                    <ul>
                      {!isMobile &&
                        <li className="usermenu-item">
                          <a href="#" className="custom-btn-v3 custom-btn-v4" title="Add Token">Add Token <i className="fas fa-caret-down"></i></a>
                          <div className="dp-menu">
                            <ul className="dp-links">
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "sft")}>
                                  Add $SFT
                                </a>
                              </li>
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "diamond")}>
                                  Add $DIAMOND
                                </a>
                              </li>
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "shibanft")}>
                                  Add $SHIBANFT
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      }
                      <li className="usermenu-item">
                        <span className="tag"></span>
                        <a href="#" className="custom-btn-v3 custom-btn-v4" title={address}><i className="fas fa-cog" /> {address ? formatAccount() : "..."} <i className="fas fa-caret-down"></i></a>
                        <div className="dp-menu">
                          <ul className="dp-links">
                            <li>
                              <a href="/my-account">
                                <i className="far fa-user-circle"></i>My Account
                              </a>
                            </li>
                            <li>
                              <a href="#" title={address} onClick={(event) => {
                                event.preventDefault();
                                signout();
                              }}><i className="fas fa-sign-out-alt"></i> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    :
                    <ul>
                      {!isMobile &&
                        <li className="usermenu-item">
                          <a href="#" className="custom-btn-v3 custom-btn-v4" title="Add Token">Add Token <i className="fas fa-caret-down"></i></a>
                          <div className="dp-menu">
                            <ul className="dp-links">
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "sft")}>
                                  Add $SFT
                                </a>
                              </li>
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "diamond")}>
                                  Add $DIAMOND
                                </a>
                              </li>
                              <li>
                                <a href="#" onClick={(event) => addToken(event, "shibanft")}>
                                  Add $SHIBANFT
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      }
                      <li className="usermenu-item">
                        <a href="#" className="custom-btn-v3 custom-btn-v4" title={"CONNECT WALLET"} onClick={(event) => {
                          event.preventDefault();
                          window.$("#js-walletPopup").modal("show");
                        }}>CONNECT WALLET</a>
                      </li>
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderForDashboard;
