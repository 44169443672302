import React, {useState, useCallback, useMemo, useEffect} from "react";

function BuyCrypto({width}) {
  useEffect(() => {
    if (window.$("#wert-widget").size() > 0) {
      const wertWidget = new window.WertWidget({
        "partner_id": "01GNSD2N1000B2FRHWA6P36YM5",
        "container_id": "wert-widget",
        "origin": "https://widget.wert.io",
        "theme": "dark",
        "commodities": "BTC:Bitcoin,ETH:Ethereum,MATIC:Polygon,USDC:Ethereum,USDC:Polygon,USDT:Ethereum,wETH:Ethereum,wETH:Polygon,wMATIC:Polygon",
        "lang": "en",
        "color_background": "#200645"
      });
      wertWidget.mount();
    }
  }, []);

  return (
    <div className="container center">
      <div className="buy-crypto">
        Step 1: Choose the crypto and amount to buy. <br/>
        Step 2: Input your wallet address. <br/>
        Step 3: SMS verification. <br/>
        Step 4: Pay with your visa or master card. <br/>
        Step 5: Receive crypto in your wallet. <br/>
        <a href="https://wiki.shibafriend.io/sports-metaverse/buy-crypto-with-credit-card-or-debit-card/supported-countries-credit-card" target="_blank" title="Supported Countries Credit Card.">Supported Countries Credit Card.</a><br/>
        <a href="https://wiki.shibafriend.io/sports-metaverse/buy-crypto-with-credit-card-or-debit-card/supported-us-states-credit-card" target="_blank" title="Supported US States Credit Card.">Supported US States Credit Card.</a>
      </div>
    </div>
  );
}

export default BuyCrypto;
