import React, {useState, useCallback, useMemo, useEffect} from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import ConnectWallet from "./ConnectWallet";
import { apiMarketUrl } from "../hooks/useFetchOption";
import { gameToken, username } from "../config/global";
import ResetPasswordPopup from "./MyAccount/ResetPasswordPopup";
import { contractConfig } from "../config/contract";

function MyAccount() {
  const { account } = useWeb3React();
  const [currentUsername, setCurrentUsername] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [resetNewPassword, setResetNewPassword] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [afferCode, setAfferCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [referrerCode, setReferrerCode] = useState("");
  const [loadingGenerateCode, setLoadingGenerateCode] = useState(false);
  const [isGeneratingCode, setIsGeneratingCode] = useState(false);
  const [shfAffiliateTier1, setShfAffiliateTier1] = useState(0);
  const [shfAffiliateTier2, setShfAffiliateTier2] = useState(0);
  const [shfAffiliateTier3, setShfAffiliateTier3] = useState(0);

  const saveEvent = useCallback(async () => {
    const accessToken = await window.localStorage.getItem(gameToken);
    if (account && accessToken && currentUsername && password && !loading) {
      setLoading(true);
      if (hasAccount) {
        console.log(newPassword);
        if (password && newPassword) {
          axios.post(`${apiMarketUrl}/game/account/change_password`, { current_password: password, new_password: newPassword, password_confirmation: newPassword}, { headers: { Authorization: `Bearer ${accessToken}`}}).then((response) => {
            console.log(response);
            toast("Saved!");
            setLoading(false);
          }).catch((error) => {
            console.log(error);
            toast("Your Password is incorrect!");
            setLoading(false);
          });
        }
      } else {
        axios.post(`${apiMarketUrl}/game/account/create`, { username: currentUsername, password: password}, { headers: { Authorization: `Bearer ${accessToken}`}}).then((response) => {
          console.log(response);
          window.localStorage.setItem(username, response?.data?.data?.user?.username);
          setHasAccount(true);
          toast("Created!");
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          toast("Account is existed!");
          setLoading(false);
        });
      }
    }
  }, [account, currentUsername, password, newPassword, loading]);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  useMemo(async () => {
    let usernameInstore = await window.localStorage.getItem(username);
    let hasAccount = usernameInstore && usernameInstore != null && usernameInstore !== "" && usernameInstore !== "null";
    setHasAccount(hasAccount);
    setCurrentUsername(hasAccount ? usernameInstore : "");
  }, [account]);

  useEffect(async () => {
    const [,ethers,signer] = await initProvider();
    let refCode = await window.localStorage.getItem("refCode");
    let affCode = await window.localStorage.getItem("shfRefCode");
    try {
      setLoadingGenerateCode(true);
      console.log(refCode);
      if (!referralCode) {
        const shfAffiliateContract = new ethers.Contract(contractConfig.shfAffiliateContract.address, contractConfig.shfAffiliateContract.abi, signer);
        const privateSaleContract = new ethers.Contract(contractConfig.privateSaleContract.address, contractConfig.privateSaleContract.abi, signer);
        const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
        const erc20Contract = new ethers.Contract(contractConfig.erc20Contract.address, contractConfig.erc20Contract.abi, signer);
        console.log(shfAffiliateContract);
        console.log(privateSaleContract);
        console.log(erc20Contract);
        console.log(storeContract);
        let userInfo = {
          referrer: 0,
          affLv1: 0,
          affLv2: 0,
          affLv3: 0,
        };
        try {
          userInfo = await storeContract.getAffiliateInfor();
          console.log(userInfo);
        } catch (error) {
          console.log(error);
        }
        console.log(userInfo);
        setAffiliateCode(userInfo?.code);
        setAfferCode(userInfo.referrer > 0 ? userInfo.referrer : affCode);

        setShfAffiliateTier1(userInfo?.affLv1?.toString());
        setShfAffiliateTier2(userInfo?.affLv2?.toString());
        setShfAffiliateTier3(userInfo?.affLv3?.toString());

        setReferrerCode((userInfo?.referrer && userInfo?.referrer > 0) ? userInfo?.referrer : refCode);
      }
      setLoadingGenerateCode(false);
    } catch (ex) {
      setLoadingGenerateCode(false);
      console.log(ex);
      setReferralCode(refCode);
    }
  }, [account, referralCode]);

  useEffect(async () => {
    if (!referralCode) {
      let generatingReferralCode = await window.localStorage.getItem("generatingReferralCode");
      const [,ethers,signer] = await initProvider();
      const shfAffiliateContract = new ethers.Contract(contractConfig.shfAffiliateContract.address, contractConfig.shfAffiliateContract.abi, signer);
      if (generatingReferralCode) {
        setIsGeneratingCode(true);
        let referralCodeValue = await shfAffiliateContract.getReferralCode();
        if (referralCodeValue.toString() !== "0") {
          setReferralCode(referralCodeValue);
          window.localStorage.removeItem("generatingReferralCode");
          setIsGeneratingCode(false);
        }
      }
    }
  }, [account, referralCode])

  const resetPassword = useCallback(async () => {
    const accessToken = await window.localStorage.getItem(gameToken);
    if (account && accessToken && !loadingReset) {
      setLoadingReset(true);
      if (hasAccount) {
        axios.post(`${apiMarketUrl}/game/account/reset_password`, {}, { headers: { Authorization: `Bearer ${accessToken}`}}).then((response) => {
          console.log(response);
          toast("Reset!");
          setResetNewPassword(response?.data?.data)
          setLoadingReset(false);
          window.$("#js-resetPasswordPopup").modal("show");
        }).catch((error) => {
          console.log(error);
          toast("Can not reset!");
          setLoadingReset(false);
        });
      }
    }
  }, [account, loadingReset, currentUsername]);

  return (
    <section className="user-main-dashboard my-account">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            { account ?
              <div className="main">
                <div className="main-box wallet-box">
                  <div className="header-area">
                    <h4>My Account</h4>
                  </div>
                </div>
                <div className="main-box wallet-box ref-code">
                  <div className="header-area">
                    <div className="form-group row">
                      <div className="col-sm-12 blue-text">NFT MYSTERY BOX AFFILIATE</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">REFERER: <span className="ref-link">{referrerCode?.toString() !== "0" ? referrerCode : ""}</span></div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">My Affiliate Link: </div>
                      <div className="col-sm-10">
                        {affiliateCode ? <a href={`${process.env.REACT_APP_HOST_URL}/affiliate/${affiliateCode}`} target="_blank" className="ref-link">{process.env.REACT_APP_HOST_URL}/affiliate/{affiliateCode}</a> : <a className="custom-btn-v2 generate-btn" href="/">{loadingGenerateCode ? "LOADING..." : "BUY MYSTERY BOX"}</a>}
                        {!affiliateCode && (loadingGenerateCode || isGeneratingCode) && <div className="ref-link help-text-link">Do no refresh this page.  Please wait up to 1 minute for blockchain transaction to complete</div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-10">
                        <table className="custom-table">
                          <thead>
                            <th colSpan={4} className="pink-text">3 TIER AFFILIATES PROGRAM</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no-border-bottom"></td>
                              <td className="center">TIER 1</td>
                              <td className="center">TIER 2</td>
                              <td className="center">TIER 3</td>
                            </tr>
                            <tr>
                              <td>
                                MYSTERY UNBOX <a href={affiliateCode ? `/affiliate/${affiliateCode}` : "/"} className="blue-text">[Link]</a><br />
                                DOG HOUSE
                              </td>
                              <td>25% Commission</td>
                              <td>15% Commission</td>
                              <td>10% Commission</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2" />
                      <div className="col-sm-10">
                        <div className="">** Referral & Affiliate fee payments are automated by smart contract to your Metamask wallet address.</div>
                        <div className="">** Sales & affiliate incomes payments in ETH.</div>
                        <div className="">** You can claim your commissions in <a href="/token">www.shibafriend.io/token</a>.</div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <div className="">Tier 1: {shfAffiliateTier1} {shfAffiliateTier1 > 0 ? "Affiliates" : "Affiliate"}</div>
                        <div className="">Tier 2: {shfAffiliateTier2} {shfAffiliateTier2 > 0 ? "Affiliates" : "Affiliate"}</div>
                        <div className="">Tier 3: {shfAffiliateTier3} {shfAffiliateTier3 > 0 ? "Affiliates" : "Affiliate"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-box wallet-box">
                  <div className="wallet-tab-content"  id="pills-tabContent">
                    <div className="col-lg-12 plan-list">
                      <div className="wallet-tab-content" id="pills-tabContent">
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                            <form autoComplete="off">
                              <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Username</label>
                                <div className="col-sm-10">
                                  <input type="text" className="form-control-plaintext" placeholder="Username" value={currentUsername} readOnly={hasAccount} autoComplete="off" onChange={(e) => setCurrentUsername(e.target.value)}/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">{hasAccount ? "Current Password" : "Password"}</label>
                                <div className="col-sm-10">
                                  <input type={isShowPassword ? "text" : "password"} className="form-control" placeholder="Password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                  <i className={isShowPassword ? "fas fa-eye-slash show-password-icon" : "fas fa-eye show-password-icon"} onClick={() => setIsShowPassword(!isShowPassword)}/>
                                </div>
                              </div>
                              {hasAccount &&
                            <div className="form-group row">
                              <label htmlFor="inputPassword" className="col-sm-2 col-form-label">New Password</label>
                              <div className="col-sm-10">
                                <input type={isShowNewPassword ? "text" : "password"} className="form-control" placeholder="New Password" autoComplete="off" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                <i className={isShowNewPassword ? "fas fa-eye-slash show-password-icon" : "fas fa-eye show-password-icon"} onClick={() => setIsShowNewPassword(!isShowNewPassword)}/>
                              </div>
                            </div>
                              }

                              <div className="form-group row form-group-actions">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label"></label>
                                <div className="col-sm-10">
                                  <a className="mybtn1" onClick={saveEvent} disabled={hasAccount ? !(password && newPassword) : !(currentUsername && password)}>{hasAccount ? (loading ? "Saving..." : "Save") : (loading ? "Creating..." : "Create Account")}</a>
                                  {hasAccount && <a className="mybtn1 c-btn-resetPassword" onClick={resetPassword}>{loadingReset ? "Reseting..." : "Reset Password"}</a>}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : <div className="main"><ConnectWallet /></div>
            }
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ResetPasswordPopup newPassword={resetNewPassword}/>
    </section>
  );
}

export default MyAccount;
