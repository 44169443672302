import { fetchWithConfig } from "../utils/helper";
import useSWR from "swr";
import useFetchOption, { apiBaseUrl } from "./useFetchOption";

export default function useAuthenticatedSwr(
  path,
  opts = {},
  baseUrl = apiBaseUrl
) {
  const { options, fullPath } = useFetchOption(path, opts, baseUrl);

  const response = useSWR([fullPath, options], fetchWithConfig);
  return response;
}
