import React, {useState, useEffect} from "react";
import numeral from "numeral";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";

import "react-toastify/dist/ReactToastify.css";
import SalePlan from "./SalePlan";

function TokenSale(props) {
  const [investor, setInvestor] = useState({});
  const [availableAmount, setAvailableAmount] = useState(0);
  const { salePlan, success } = props;
  const { account } = useWeb3React();

  function getData() {
    if (salePlan?.id) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/investors/${salePlan.id}/${account}`, { headers: {"X-Api-Key": process.env.REACT_APP_API_KEY} }).then((response) => {
        setInvestor(response.data.data);
      }).catch(() => {
        setInvestor({});
      });
    }
  }

  useEffect(() => {
    if (account && salePlan) {
      getData();
    } else {
      setInvestor({});
    }
  },[account, salePlan]);

  useEffect(() => {
    if (investor.amount > 0 && salePlan.minAmount > 0 && investor.amount <= salePlan.minAmount) {
      setAvailableAmount(salePlan.minAmount);
    } else {
      if (investor.amount > 0 && salePlan.maxAmount > 0 && investor.amount >= salePlan.maxAmount) {
        setAvailableAmount(salePlan.maxAmount);
      } else {
        setAvailableAmount(investor.amount);
      }
    }
  },[investor, salePlan]);

  function successEvent(message) {
    getData();
    success(message);
  }

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="header-area">
                  <h4>TOKEN SALES</h4>
                </div>
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="wallet-tab-menu">
                    <ul className="nav submenu" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="pills-wt1-tab" data-toggle="pill" href="#pills-wt1" role="tab" aria-controls="pills-wt1">{salePlan.name}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="current-balance">
                              <p>COIN ALLOCATED</p>
                              <h4>
                                {numeral(investor ? availableAmount / salePlan.price : 0).format("0,0.0000")} <span>SFT</span>
                              </h4>
                              <div className="t-sm right-text">
                                {numeral(investor ? availableAmount : 0).format("0,0")} BUSD
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8 plan-list">
                            {salePlan && <SalePlan plan={salePlan} investor={investor} success={(message) => successEvent(message)}/>}
                            <div className="row stake-plan-item">
                              <div className="col-lg-2" />
                              <div className="col-lg-10">
                                <p><h3>HOW TO BUY</h3></p>
                                You need to be whitelisted to buy SFT.<br />
                                {salePlan?.handle === "preido" ? 
                                  <div>
                                    TGE 18%, End of 2nd Month 41%, and End of 3rd Month 41% <br />
                                  </div> :
                                  <div>
                                    Token will claimable {salePlan?.handle === "privatesale1" ? "15 Days" : (salePlan?.handle === "privatesale2" ? "7 Days" : (salePlan.handle === "seed" ? "3 Months" : "3 Days"))} after IDO.<br />
                                    Token is daily linear vesting for 18 months.<br />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TokenSale;
