import React from "react";
import ReactDOM from "react-dom";
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import "./assets/css/bootstrap.min.css";
import "./assets/css/plugin.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./index.css";
import App from "./App";
import en from "./translations/en.json";
import vi from "./translations/vi.json";
import cn from "./translations/cn.json";

const POLLING_INTERVAL = 12000

function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: "en",
  lng: getCookie("locale") || "en",
  resources: {
    en: {
      common: en
    },
    vi: {
      common: vi
    },
    cn: {
      common: cn
    }
  },
  ns: ["common"]
});

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <I18nextProvider i18n={i18next}>
        <App i18n={i18next}/>
      </I18nextProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
