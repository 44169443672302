import React, {useState, useEffect} from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import "react-toastify/dist/ReactToastify.css";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";

import { contractConfig } from "../config/contract";

function PreIdo(props) {
  const { account } = useWeb3React();
  const [minAmount, setMinAmount] = useState(100);
  const [tokenPrice, setTokenPrice] = useState(0.0036);
  const [amount, setAmount] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);

  async function initProvider(){
    const provider =  new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider, ethers, signer];
  }

  async function buyEvent(event) {
    event.preventDefault();

    if (isLoading) {
      return false;
    }
    setIsLoading(true);

    const [provider,ethers,signer] = await initProvider();

    const preIdoContract = new ethers.Contract(contractConfig.preIdoContract.address, contractConfig.preIdoContract.abi, signer);
    console.log(preIdoContract);
    let latestPrice = await preIdoContract.getLatestPrice(amount);
    let bnbBalance = await provider.getBalance(await signer.getAddress());
    bnbBalance = new BigNumber(bnbBalance.toString());

    if (bnbBalance.isLessThan(latestPrice.toString())) {
      toast("Insufficient ETH");
      setIsLoading(false);
      return false;
    }

    try {
      let response = await preIdoContract.buy({ value: latestPrice.toString()});
      await provider.waitForTransaction(response.hash);
      toast("Payment Success!");
      setIsLoading(false);
      setPaidAmount((amount / tokenPrice).toString());
    } catch (ex) {
      console.log(ex);
      toast("Payment Failed! Please try again!");
      setIsLoading(false);
    }
  }

  function onBlur(event) {
    if(parseFloat(minAmount) > 0 && parseFloat(amount) > 0 && parseFloat(amount) <= parseFloat(minAmount)) {
      setAmount(minAmount);
    }
  }

  function handleChange(event) {
    console.log(event.target.value);
    setAmount(event.target.value);
  }

  useEffect(async () => {
    const [,ethers,signer] = await initProvider();
    const preIdoContract = new ethers.Contract(contractConfig.preIdoContract.address, contractConfig.preIdoContract.abi, signer);
    console.log(preIdoContract);
    let min = await preIdoContract.minAmount();
    let price = await preIdoContract.tokenPrice();
    
    let userInfo = {
      totalTokenAmount: 0
    };
    console.log(await signer.getAddress());
    try {
      userInfo = await preIdoContract.getInfo();
    } catch (error) {
      console.log(error);
    }
    console.log(userInfo);
    price = new BigNumber(price.toString()).dividedBy(10000);
    setPaidAmount(new BigNumber(userInfo?.totalTokenAmount?.toString()).dividedBy(1000000000).toString());
    setMinAmount(min.toString());
    setTokenPrice(price.toString());
    if (userInfo.totalTokenAmount.toString() !== "0") {
      setAmount(new BigNumber(userInfo.totalTokenAmount.toString()).multipliedBy(price).dividedBy(1000000000).toString());
    } else {
      console.log(amount);
      if(parseFloat(min.toString()) > 0 && parseFloat(amount) <= parseFloat(min.toString())) {
        setAmount(min.toString());
      }
    }
  }, [account])

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="header-area">
                  <h4>TOKEN SALES</h4>
                </div>
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="wallet-tab-menu">
                    <ul className="nav submenu" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="pills-wt1-tab" data-toggle="pill" href="#pills-wt1" role="tab" aria-controls="pills-wt1">SFT PRE IDO</a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="current-balance">
                              <p>COIN ALLOCATED</p>
                              <h4>
                                <span>{paidAmount.toString()}</span> <span>SFT</span>
                              </h4>
                            </div>
                          </div>
                          <div className="col-lg-8 plan-list">
                            <div className="row stake-plan-item">
                              <div className="col-lg-2"><img alt="" src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon" /></div>
                              <div className="col-lg-10">
                                <div className="stake-plan-head">1 SFT = ${numeral(tokenPrice).format("0.0000")}</div>
                                <div className="">
                                  <div className="stake-plan-label stake-plan-label-presale">Min: ${numeral(minAmount).format("0,0")}</div>
                                </div>
                                <div className="stake-plan-form row">
                                  <div className="col-lg-6">
                                    <div className="stake-plan-label-presale">
                                      <div className="blue-text label-control no-shadow">Buy with (ETH)</div>
                                    </div>
                                    <div className="">
                                      <input
                                        type="number"
                                        name="amount"
                                        className="amount-input"
                                        disabled={paidAmount.toString() !== "0"}
                                        value={amount}
                                        min={minAmount}
                                        onChange={handleChange}
                                        onBlur={onBlur}
                                      />
                                    </div>
                                    <div className="stake-plan-label-presale">{numeral(amount / tokenPrice).format("0,0.0000")} SFT</div>
                                  </div>
                                </div>
                                <div className="stake-plan-btn">
                                  {account ? <a className="custom-btn-v3" onClick={buyEvent} disabled={paidAmount.toString() !== "0"}>{paidAmount.toString() !== "0" ? "PAID" : (isLoading ? "BUYING..." : "BUY")}</a> : <a className="mybtn1" onClick={(event) => {
                                    event.preventDefault();
                                    window.$("#js-walletPopup").modal("show");
                                  }}>CONNECT WALLET</a>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreIdo;
