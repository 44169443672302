function Popup(props) {
  let {title, body, imageUrl, numberOfOwners} = props;
  return (
    <div className="modal fade popup" id="js-popup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text">{title}</h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            <div className="popup-body">
              <div className="image">
                <img src={imageUrl} alt=""/>
              </div>
              <div className="popup-body-right">
                <a className="btn-custom mybtn1">
                  Buy
                  <span className="tag">SOON</span>
                </a>
                <div className="number-of-owners">
                  <span className="blue-text">{numberOfOwners}</span> OWNERS
                </div>
              </div>
            </div>
            <div className="popup-description">{body}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
