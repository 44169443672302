import "@google/model-viewer";
import {isMobile} from "react-device-detect";

function WinPrizes({width}) {
  return (
    <section className="c-section c-winPrizes__section">
      <div className="container">
        <div className="row">
          {isMobile && <div className="col-xl-6 col-lg-6 col-md-6 left-content">
            <div className="head blue-text no-shadow">
              WIN PRIZES
            </div>
          </div>}
          {isMobile && <div className="col-xl-6 col-lg-6 col-md-6 right-content">
            <div className="">
              <div className="head pink-text">
                Win BIG Win EASY!
              </div>
            </div>
          </div>}
          <div className="center-graphic">
            <img src="/assets/images/center-graphic.png" alt="ShibafriendNFT"/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 left-content">
            {!isMobile && <div className="head blue-text no-shadow">
              WIN PRIZES
            </div>}
            <div className="explain-item">
              <div className="title pink-text">
                1 BUY
              </div>
              <div className="subTitle">
                SHIBA SFT and use it to<br />
                play in Campaign
              </div>
            </div>
            <div className="explain-item">
              <div className="title pink-text">
                2 JOIN
              </div>
              <div className="subTitle">
                3 Days Game Campaign
              </div>
            </div>
            <div className="explain-item">
              <div className="title pink-text">
                3 PAY
              </div>
              <div className="subTitle">
                1000 SFT (about $10)<br />
                to start play Shiba Run <br />{!isMobile && <br />}
              </div>
              {!isMobile && <div className="subTitle">
                The SFT <br />
                goes to Winning pools <br />
                <span className="help-text">50% Platform fee for each game</span>
              </div>}
            </div>
            {!isMobile && <div className="explain-item">
              <div className="last-item">
                <b>1SFT = $0.01 Fixed</b>
              </div>
            </div>}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 right-content">
            {!isMobile &&<div className="head pink-text">
              Win BIG Win EASY!
            </div>}
            <div className="explain-item">
              <div className="title pink-text">
                {isMobile ? "4 SCORE" : "SCORE 4"}
              </div>
              <div className="subTitle">
                TOP 20 in Leaderboard
              </div>
            </div>
            <div className="explain-item">
              <div className="title pink-text">
                {isMobile ? "5 WIN" : "WIN 5"}
              </div>
              <div className="subTitle">
                SFT Prizes from the pool
              </div>
            </div>
            <div className="explain-item">
              <div className="title pink-text">
                {isMobile ? "6 SWAP" : "SWAP 5"}
              </div>
              <div className="subTitle">
                Claim SFT to <b>Wallet</b><br/>
                Swap SFT to <b>USDC</b>
              </div>
            </div>
            <div className="explain-item">
              <div className="last-item">
                <b>Buy Shiba NFT = Gain 3 Lives</b><br />
                <span className="help-text">in each gameplay & increase</span><br />
                <span className="help-text">your chances of gaining higher scores</span>
              </div>
              {isMobile && <div className="last-item">
                The SFT <br />
                goes to Winning pools <br />
                <span className="help-text">50% Platform fee for each game</span>
              </div>}
              {isMobile && <div className="last-item">
                <b>1SFT = $0.01 Fixed</b><br />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WinPrizes;
