import React from "react";
import { ToastContainer } from "react-toastify";

import HeaderForDashboard from "../components/HeaderForDashboard";
import InvestorDetail from "../components/InvestorDetail";
import WalletListPopup from "../components/WalletListPopup";

function TokenPage(props) {
  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"token"}/>
      <InvestorDetail />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default TokenPage;
