import "@google/model-viewer";

function PlayGame({width}) {
  return (
    <section className="c-nftPet__section c-nftPlayGame__section">
      <div className="container">
        <div className="row">
          <div className="col-12 left-content">
            <div className="head">
              <img src="/img/shiba-metaverse.png" className="metaverse-icon" alt="Shibafriend NFT"/>
            </div>
            <div className="links">
              <a href="https://musicbeat.shibafriend.io" className="music-beat-btn" target="_blank" rel="noreferrer">PLAY MUSIC BEAT</a>
              <a href="/MusicBeat.apk" className="download-btn" target="_blank">ANDROID</a>
            </div>
            <div className="links">
              <a href="https://shibaviewer.shibafriend.io" className="music-beat-btn" target="_blank" rel="noreferrer">SHIBA 3D VIEWER</a>
              <a href="/ShibaPreview.apk" className="download-btn" target="_blank">ANDROID</a>
            </div>
            <div className="model-viewer-left">
              <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_King.glb" camera-controls shadow-intensity="1">
                <div className="lazy-load-poster" slot="poster">Loading...</div>
              </model-viewer>
            </div>
            <div className="model-viewer-right">
              <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Queen.glb" camera-controls shadow-intensity="1">
                <div className="lazy-load-poster" slot="poster">Loading...</div>
              </model-viewer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayGame;
