import React, {useEffect, useState} from 'react'
import {isMobile} from 'react-device-detect';
import useAuth from '../hooks/useAuth'
import { connectors, connectorsForMobile } from "./wallet/Config"
import { connectorLocalStorageKey, walletLocalStorageKey } from './wallet/Config'

function TermsOfServicePopup(props) {
  return (
    <div className="modal fade popup" id="js-termsOfServicePopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text">Terms of Service</h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            <div className={isMobile ? 'row is-mobile' : 'row'}>
              The following terms and conditions govern all use of the Shibafriend website and all content, services, and products available through the website, including, but not limited to, the Shibafriend Client Area, Shibafriend Community, Shibafriend Demo (collectively referred to as the Website).

              <strong>The Website is owned and operated by Shibafriend (“Shibafriend”).</strong>
              The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Shibafriend's Privacy Policy), and procedures that may be published from time to time on this Site by Shibafriend (collectively, the “Agreement”).
              <br />Please read this Agreement carefully before accessing or using the Website. By accessing or using any part of the website, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Website or use any services. If these terms and conditions are considered an offer by Shibafriend, acceptance is expressly limited to these terms. The Website is available only to individuals who are at least 13 years old.

              <strong>Account of Website.</strong>
              You are responsible for maintaining the security of your account of the Website, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must immediately notify Shibafriend of any unauthorized uses of your account or any other breaches of security. Shibafriend will not be liable for any acts or omissions by You, including any damages of any kind incurred as a result of such acts or omissions.

              <strong>Contribution to Website.</strong>
              If you leave comments anywhere on the Website, post material to the Website, post links on the Website, or otherwise make (or allow any third party to make) material available by means of the Website (any such material, “Content”), You are entirely responsible for the content of, and any harm resulting from, that Content. That is the case regardless of whether the Content in question constitutes text, graphics, audio, or computer software. By making Content available, you represent and warrant that the downloading, copying, and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, or trade secret rights, of any third party;
              <br />you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;
              <br />the Content does not contain or install any viruses, worms, malware, Trojan horses, or other harmful or destructive content;
              <br />the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third-party sites or boost the search engine rankings of third-party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);
              <br />the Content is not pornographic, libelous, or defamatory, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party;
              <br />By submitting Content to Shibafriend for inclusion on our Website, you grant Shibafriend a worldwide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content for the purpose of displaying, distributing, promoting, marketing or any other lawful use.
              Without limiting any of those representations or warranties, Shibafriend has the right (though not the obligation) to, in Shibafriend's sole discretion (i) refuse or remove any content that, in Shibafriend's reasonable opinion, violates any policy or is in any way harmful or objectionable, or (ii) terminate or deny access to and use of the Website to any individual or entity for any reason, in Shibafriend’s sole discretion. Shibafriend will have no obligation to provide a refund of any amounts previously paid under these circumstances.

              <strong>Payment.</strong>
              The Website offers numerous products and services for sale. The Website does not handle payments for these products directly but rather refers these payments to a secure third-party payment processor which handles all aspects of the payment process. Any payment issues or disputes should be resolved directly with the payment processor. Once we have been notified by the payment processor that a payment has been made, and that the payment has successfully passed a fraud review, access will be granted to the product or service being purchased as soon as possible, however, we make no guarantees of timeliness or immediacy.

              <strong>Newsletter.</strong>
              If you choose to request a download link for the free trial version of Shibafriend or purchase a Shibafriend license, we will add your email address to the Shibafriend Newsletter (as noted on the free trial request page). Our newsletter is designed to keep you up to date with Shibafriend-related developments, updates, releases, and other relevant items. You can instantly unsubscribe from this mailing list at any time you like by clicking the unsubscribe link provided in the newsletter email messages. You may also join the newsletter from our blog.

              <strong>Responsibility of Website Visitors.</strong>
              By operating the Website, Shibafriend does not represent or imply that it endorses any or all of the contributed content, or that it believes such material to be accurate, useful or non-harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. The Website may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. Shibafriend disclaims any responsibility for any harm resulting from the use by visitors of the Website.
              <br />Copyright Infringement and DMCA Policy. As Shibafriend asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by Shibafriend.com violates your copyright, you are encouraged to notify Shibafriend in accordance with common DMCA policies. Shibafriend will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. In the case of a visitor who may infringe or repeatedly infringes the copyrights or other intellectual property rights of Shibafriend or others, Shibafriend may, in its discretion, terminate or deny access to and use of the Website. In the case of such termination, Shibafriend will have no obligation to provide a refund of any amounts previously paid to Shibafriend.
              
              <strong>Intellectual Property.</strong>
              This Agreement does not transfer from Shibafriend to you any Shibafriend or third party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with Shibafriend. Shibafriend, Shibafriend, Shibafriend.com, Shibafriend.com logo, and all other trademarks, service marks, graphics, and logos used in connection with Shibafriend.com, or the Website are trademarks or registered trademarks of Shibafriend or Shibafriend’s licensors. Other trademarks, service marks, graphics, and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any Shibafriend or third-party trademarks.
              
              <strong>Changes.</strong>
              Shibafriend reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes acceptance of those changes. Shibafriend may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.

              <strong>Termination.</strong>
              Shibafriend may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your account of Website (if you have one), you may simply discontinue using the Website. Notwithstanding the foregoing, if you have an account, such account can only be terminated by Shibafriend if you materially breach this Agreement and fail to cure such breach within thirty (30) days of Shibafriend's notice to you thereof; provided that, Shibafriend can terminate the Website immediately as part of a general shut down of our service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

              <strong>Disclaimer of Warranties.</strong>
              The Website is provided "as is". Shibafriend and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose, and non-infringement. Neither Shibafriend nor its suppliers and licensors make any warranty that the Website will be error-free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.

              <strong>Limitation of Liability.</strong>
              In no event will Shibafriend, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for (i) any special, incidental or consequential damages; (ii) the cost of procurement of substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Shibafriend under this agreement during the twelve (12) month period prior to the cause of action. Shibafriend shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.

              <strong>General Representation and Warranty.</strong>
              You represent and warrant that (i) your use of the Website will be in strict accordance with the Shibafriend Privacy Policy, with this Agreement, and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental areas, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside) and (ii) your use of the Website will not infringe or misappropriate the intellectual property rights of any third party.

              <strong>Indemnification.</strong>
              You agree to indemnify and hold harmless Shibafriend, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePopup;
