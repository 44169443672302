import React, {useEffect, useState} from "react";
import "@google/model-viewer";
import { isMobile } from "react-device-detect";

function RollResultPopup(props) {
  const {nftItems} = props;

  return (
    <div className="modal fade popup" id="js-rollResultPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-body roll-result-body">
            <h4 className="yellow-text">YOU HAVE RECEIVED:</h4>
            <div className="row roll-result-row">
              {(nftItems || []).map((item, index) => 
                <div className={isMobile ? "col-4" : "col-lg-3"} key={index}>
                  <model-viewer class="marketplace-viewer slider-item-viewer" src={item.animation_url} camera-controls shadow-intensity="1">
                    <div className="lazy-load-poster" slot="poster">Loading...</div>
                  </model-viewer>
                </div>
              )}
            </div>
            <div className="row roll-result-row btns">
              <a href="/nft-game-assets" className="yellow-btn">Go to NFT ASSETS</a>
              <a className="pink-btn" onClick={(e) => {
                e.preventDefault();
                window.$("#js-rollResultPopup").modal('hide');
              }}>DONE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RollResultPopup;
