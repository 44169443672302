import React, {useMemo, useState, useCallback} from "react";
import { toast} from "react-toastify";

import { currencies } from "../../config/currency";
import { contractConfig } from "../../config/contract";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import {isMobile} from "react-device-detect";

function SellPopup({ nftItem }) {
  const [currency] = useState("0x0000000000000000000000000000000000000000");
  const [sellPrice, setSellPrice] = useState();
  const [loading, setLoading] = useState(false);
  const { account }= useWeb3React();
  const currentCurrency = useMemo(
    () => currencies.find((u) => u.address.toLowerCase() === currency?.toLowerCase()),
    [currency]
  );

  const sellEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (!account || loading) {
        return true;
      }

      setLoading(true);
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(contractConfig.marketContract.address, contractConfig.marketContract.abi, signer);
      const petContract = new ethers.Contract(contractConfig.petContract.address, contractConfig.petContract.abi, signer);
      console.log("Approving...");
      const approve = await petContract.approve(contractConfig.marketContract.address, nftItem.token_id);

      if (!approve.hash) {
        toast("Can not approve this transaction. please retry again later!");
        setLoading(false);
        return false;
      }

      await provider.waitForTransaction(approve.hash);
      console.log("Approved...");

      console.log("Create Trading...");
      console.log(sellPrice.toString());
      const createTradingData = await contract.createTrading(contractConfig.petContract.address, nftItem.token_id, sellPrice.toString());

      await provider.waitForTransaction(createTradingData.hash);
      
      setLoading(false);
      window.$("#js-sellPopup").modal("hide");
      toast("Sold");

    } catch (error) {
      console.log(error);
      toast(error?.data?.message.toString());
      setLoading(false);
    }
  }, [nftItem, currency, account, loading, sellPrice, currentCurrency?.decimals]);

  return (
    <div className="modal fade popup" id="js-sellPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text"> </h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            <div className="c-sellPopup__row">
              SELL SHIBA TOKEN ID: {nftItem?.token_id}
            </div>
            <div className="c-sellPopup__row">
              Your Assets will listed in Marketplace with a fixed price. In order to get it back, you'll have to cancel the sale.
            </div>
            <div className="c-sellPopup__row form-group">
              Sell at <span className="dollar-icon">$</span><input type="number" value={sellPrice} onChange={(e) => setSellPrice(e.target.value)} className="input-sell-price"/>
            </div>
            <div className="c-sellPopup__row">
              You will receive 95% Sale Price of BNB / BUSD / SFT after a 5% fee.
            </div>
            <div className="c-sellPopup__row">
              I understand that after successfully sold, Shiba level will reset to 1.
            </div>
            <div className="sell-popup-actions"><a className="mybtn1" onClick={sellEvent}>{loading ? "Loading..." : "Confirm"}</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellPopup;
