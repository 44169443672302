import React, {useState} from "react";

import Header from "../components/Header";
import SubscribeSection from "../components/SubscribeSection";
import Footer from "../components/Footer";
import SectionFour from "../components/SectionFour";
import WalletListPopup from "../components/WalletListPopup";

function MysteryBox(props) {
  const [currency, setCurrency] = useState("BNB");
  let {defaultWidth, height, width, i18n} = props;

  return (
    <div>
      <Header height={height} width={width} ratio={width/defaultWidth} i18n={i18n} currentRoute={"mystery-box"}/>
      <section className="mystery-box">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <img src="assets/images/s-box.png" alt=""/>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <h3>MYSTERY BOX</h3>
              <h5 className="pink-text">USD$20 per box 0.0017BNB</h5>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="blue-text label-control">Quantity</div>
                  <select className="qty-control">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="blue-text label-control">Buy with</div>
                  <div className="mybtn1 switch-item">
                    <img src="img/shibacoin-gold2-1050.png"
                      alt=""
                      className={currency === "SFT" ? "selected": ""}
                      onClick={() => setCurrency("SFT")}/>
                    <img src="img/BNB-icon.png"
                      className={`is-right ${currency === "BNB" ? "selected": ""}`}
                      onClick={() => setCurrency("BNB")}/>
                  </div>
                </div>
              </div>
              <a className="mybtn1 buy-btn btn-custom">
                <span className="tag">SOON</span>
                BUY with {currency}!
              </a>
            </div>
          </div>
        </div>
      </section>
      <SectionFour />
      <SubscribeSection />
      <Footer />
      <WalletListPopup />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
    </div>
  );
}



export default MysteryBox;
