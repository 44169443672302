import React, {useState, useCallback} from "react";
import numeral from "numeral";
import axios from "axios";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { apiMarketUrl } from "../../hooks/useFetchOption";
import { gameToken } from "../../config/global";
import { contractConfig } from "../../config/contract";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

function Diamond({ diamond }) {
  const accessToken = window.localStorage.getItem(gameToken);
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React();
  const claimEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (loading || diamond?.total <= 0) {
        return true;
      }

      setLoading(true);
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(contractConfig.gameContract.address, contractConfig.gameContract.abi, signer);
      const shfContract = new ethers.Contract(contractConfig.shfContract.address, contractConfig.shfContract.abi, signer);
      let shfbalance = await shfContract.balanceOf(await signer.getAddress());

      shfbalance = new BigNumber(shfbalance.toString());
      
      let amount  = new BigNumber(diamond?.total);
      let amountWithDecimal = amount.multipliedBy(1000000000);

      //Log the amount 
      console.log("Amount : ", shfbalance.toString(), amountWithDecimal.toString());
      //Validate the balance 
      if (shfbalance.isLessThan(amountWithDecimal)) {
        toast("Insufficient SFT Balance");
        setLoading(false);
        return false;
      }

      axios.post(`${apiMarketUrl}/game/claim`, {}, { headers: { Authorization: `Bearer ${accessToken}`}}).then(async (res) => {
        console.log(res);
        let claimAmount = new BigNumber(res?.data?.data?.amount);
        let claimAmountWithDecimal = claimAmount.multipliedBy(1000000000);
        
        console.log("Approving...");
        const approve = await shfContract.approve(contractConfig.gameContract.address, claimAmountWithDecimal.toString());

        await provider.waitForTransaction(approve.hash);
        console.log("Approved");

        const claimDiamond = await contract.claimDiamond(res?.data?.data?.id, claimAmountWithDecimal.toString(), res?.data?.data?.rate, res?.data?.data?.signature);
        console.log(claimDiamond);

        await provider.waitForTransaction(claimDiamond.hash);
        toast("Claimed!");
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [diamond?.total, loading, account, accessToken])

  const retryEvent = useCallback((event) => {
    event.preventDefault();
    window.$("#js-unclaimedPopup").modal("show");
  }, [diamond])

  return (
    <div className="row stake-plan-item">
      <div className="col-lg-3"><img alt=""src="/assets/images/Shibafriend-Diamond.png" className="stake-plan-icon" /></div>
      <div className="col-lg-9">
        <div className="stake-plan-head">EARN DIAMOND</div>
        <div className="">Daily MAX Earning: <span className="blue-text no-shadow">{numeral(100000).format("0,0")}</span></div>
        <div className="">Today Earned Diamond: <span className="blue-text no-shadow">{numeral(diamond?.todayEarned).format("0,0.0000")}</span></div>
        <div className="">Claimed: <span className="blue-text no-shadow">{numeral(diamond?.claimed).format("0,0.0000")}</span></div>
        <div className="">Available to Claim: <span className="pink-text no-shadow">{numeral(diamond?.total).format("0,0.0000")}</span></div>
        <div className="">Unclaimed: <span className="blue-text no-shadow">{numeral(diamond?.unclaimed).format("0,0.0000")}</span></div>
        <div className="stake-plan-btn">
          {<a className="custom-btn-v2" onClick={claimEvent} disabled={diamond?.total <= 0}>{loading ? "Loading..." : "Claim"}</a>}
          {diamond?.unclaimed > 0 && <a className="custom-btn-v3 btn-retry" onClick={retryEvent}>Retry</a>}
        </div>
      </div>
    </div>
  );
}

export default Diamond;
