function NotFound(props) {
  let { title } = props;
  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main f-z-f">
              <div className="content">
                <h4>{title || "Page Not Found"}</h4>
                <a href="/" className="mybtn1">Go Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
