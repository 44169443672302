import React, {useState, useMemo, useCallback, useEffect} from "react";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import { parameterize, removeEmpty } from "../../utils/helper";
import useAuthenticatedSwr from "../../hooks/useAuthenticatedSwr";
import { apiMarketUrl } from "../../hooks/useFetchOption";
import { fetchWithConfig } from "../../utils/helper";
import { currencies } from "../../config/currency";
import { contractConfig } from "../../config/contract";

import NftItem from "./NftItem";
import NftItemInMarket from "../Marketplace/NftItem";
const md5 = require("md5");

function NftStoreIndex(props) {;
  const { account } = useWeb3React();
  const [currency, setCurrency] = useState();
  const [sortType, setSortType] = useState("latest");
  const [sales, setSales] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [referrerCode, setReferrerCode] = useState("");
  const [isLoadingReferrer, setIsLoadingReferrer] = useState(true);
  const [requestParams, setRequestParams] = useState({
    page: 1,
    per_page: 30,
    order_by: "created_at",
    order_type: "desc",
    min_price: 0,
    max_price: null,
    currency: null,
    designs: [],
    tiers: [],
    keyword: "",
  });

  const [search, setSearch] = useState({});

  const doSearch = useCallback(() => {
    setRequestParams((prev) => ({
      ...prev
    }));
  }, [search, sortType]);

  const currentResult = useMemo(() => {
    let result = sales;
    result = result.filter((sale) => sale.status === 0);
    if (requestParams?.currency != null && requestParams?.currency != "") {
      result = result.filter((sale) => sale.currency?.toLowerCase() === requestParams?.currency?.toLowerCase())
    }

    if (requestParams?.tiers.length > 0) {
      result = result.filter((sale) => requestParams?.tiers.indexOf(sale.tier.toString()) >= 0)
    }

    if (requestParams?.designs.length > 0) {
      result = result.filter((sale) => requestParams?.designs.indexOf(sale.batchId.toString()) >= 0)
    }

    if (!isNaN(requestParams?.min_price) && requestParams?.min_price !== 0) {
      console.log(requestParams?.min_price);
      let minPrice = new BigNumber(requestParams?.min_price);
      result = result.filter((sale) => minPrice.isLessThan(sale.price) || minPrice.toString() === "0");
    }

    if (!isNaN(requestParams?.max_price) && requestParams?.max_price != null) {
      let maxPrice = new BigNumber(requestParams?.max_price);
      result = result.filter((sale) => maxPrice.isGreaterThan(sale.price));
    }

    if (requestParams.keyword !== "") {
      result = result.filter((sale) => sale.name.toLowerCase().includes(requestParams.keyword.toLowerCase()))
    }

    result = result.sort((sale) => -parseInt(sale.id));

    return result;
  },
  [sales, requestParams]
  );

  const endpointDesign = useMemo(
    () => `${apiMarketUrl}/public/designs?per_page=100`,
    []
  );

  const endpointTiers = useMemo(
    () => `${apiMarketUrl}/public/tiers`,
    []
  );

  const marketEndpoint = useMemo(
    () => `/public/markets?${queryString.stringify(removeEmpty(requestParams))}`,
    [requestParams]
  );

  const designFor = useCallback(
    (id) => (designs || []).find((item) => item.id.toString() === id.toString()),
    [designs]
  );

  const tierFor = useCallback(
    (id) => (tiers || []).find((item) => item.id.toString() === id.toString()),
    [tiers]
  );

  useEffect(async () => {
    try {
      setIsLoading(true);
      let designsRes = await fetchWithConfig(endpointDesign, {});
      console.log("D", designsRes);
      setDesigns(designsRes?.data);
      let tiersRes = await fetchWithConfig(endpointTiers, {});
      setTiers(tiersRes?.data);
      const [,ethers,signer] = await initProvider();

      const storeContract = new ethers.Contract(contractConfig.storeContractBNB.address, contractConfig.storeContractBNB.abi, signer);
      let result = await storeContract.getAllSales();
      result = result.map((item, index) => {
        let design = (designsRes?.data || []).find((design) => design.id.toString() === parseInt(item[4]).toString());
        let tier = (tiersRes?.data || []).find((tier) => tier.id.toString() === parseInt(item[5]).toString());
        let name = `${design?.name}#${tier?.name}`;
        let handle = parameterize(`${design?.name} ${tier?.name} ${md5(index)}`);
        return {
          ...item,
          id: index,
          batchId: parseInt(item[4]),
          tier: parseInt(item[5]),
          price: parseFloat(item[1]),
          status: parseInt(item[3]),
          handle: handle,
          design: design,
          tierInfo: tier,
          name: name
        };
      });
      console.log(result);
      setSales(result);
      setIsLoading(false);
    } catch (error) {
      console.log(error?.message);
      setIsLoading(false);
    }
  },[currencies]);

  useEffect(async () => {
    try {
      setIsLoadingReferrer(true);
      let refCode = await window.localStorage.getItem("refCode");
      const [,ethers,signer] = await initProvider();
      const shfAffiliateContract = new ethers.Contract(contractConfig.shfAffiliateContract.address, contractConfig.shfAffiliateContract.abi, signer);
      let referCode = await shfAffiliateContract.getReferrerCode();
      setReferrerCode(referCode ? referCode : refCode);
      setIsLoadingReferrer(false);
    } catch (error) {
      setIsLoadingReferrer(false);
    }
  }, [account]);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  const currentCurrency = useMemo(
    () => currencies.find((u) => u.address.toLowerCase() === currency?.toLowerCase()),
    [currency]
  );

  const handlePageClick = useCallback((event) => {
    setRequestParams((prev) => ({
      ...prev,
      page: event.selected + 1,
    }));
  }, []);

  const selectDesignEvent = useCallback((event) => {
    let filterDesigns = requestParams.designs;
    if (event.target.checked) {
      filterDesigns.push(event.target.value);
    } else {
      filterDesigns.splice(filterDesigns.indexOf(event.target.value), 1);
    }
    setRequestParams((prev) => ({
      ...prev,
      designs: filterDesigns
    }));
  }, []);

  const selectTierEvent = useCallback((event) => {
    let filterTiers = requestParams.tiers;
    if (event.target.checked) {
      filterTiers.push(event.target.value);
    } else {
      filterTiers.splice(filterTiers.indexOf(event.target.value), 1);
    }
    setRequestParams((prev) => ({
      ...prev,
      tiers: filterTiers
    }));
  }, []);

  const { data: nfts, error } = useAuthenticatedSwr(marketEndpoint, {}, apiMarketUrl);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="header-area">
                  <h4>NFT MARKETPLACE</h4>
                  <div>REFERER: <span className="ref-link">{referrerCode?.toString() !== "0" ? referrerCode : (isLoadingReferrer ? "Loading..." : "")}</span></div>
                </div>
                <div className="wallet-tab-menu">
                  <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="pills-wt1-tab" data-toggle="pill" href="#pills-wt1" role="tab" aria-controls="pills-wt1">
                            PETs
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <span className="tag">SOON</span>
                      <a className="nav-link" id="pills-wt2-tab" data-toggle="pill" href="#pills-wt2" role="tab" aria-controls="pills-wt2">Land</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <span className="tag">SOON</span>
                      <a className="nav-link" id="pills-wt3-tab" data-toggle="pill" href="#pills-wt3" role="tab" aria-controls="pills-wt3">Home Buildings</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <span className="tag">SOON</span>
                      <a className="nav-link" id="pills-wt3-tab" data-toggle="pill" href="#pills-wt3" role="tab" aria-controls="pills-wt3">Commerial Buildings</a>
                    </li>
                  </ul>
                </div>
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="row c-filter c-filter__left">
                                Filter <a href="/nft-store" className="c-filter__btnReset">Reset</a>
                            </div>
                            <div className="row c-filter c-filter__list">
                              <div className="accordion c-filterItem" id="tierFilter">
                                <div className="card">
                                  <div className="card-header" id="headingTwo">
                                    <button className="btn btn-link collapsed accordion-button" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                        BUY SHIBA
                                    </button>
                                  </div>
                                  <div id="collapse1" className="collapse show" aria-labelledby="headingTwo" data-parent="#tierFilter">
                                    <div className="card-body c-filter__tiers">
                                      {(tiers || []).map((design, index) =>
                                        <div className="c-designItem custom-checkbox" key={index}>
                                          <input type="checkbox" id={index} className="custom-control-input" value={design.id} onChange={selectTierEvent}/>
                                          <label htmlFor={index} className="custom-control-label">{design.name}</label>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion c-filterItem" id="priceFilter">
                                <div className="card">
                                  <div className="card-header" id="headingOne">
                                    <button className="btn btn-link accordion-button" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Price
                                    </button>
                                  </div>

                                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#priceFilter">
                                    <div className="card-body price-body">
                                      <div className="c-filter__currency">
                                        <div className="custom-dropdown dropdown show">
                                          <a className="mybtn2 btnSort dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {currentCurrency ? currentCurrency?.text : <span>All Currencies</span>}
                                          </a>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" onClick={(e) => {
                                              e.preventDefault();
                                              setCurrency("");
                                              setRequestParams((prev) => ({ ...prev, currency: "" }))}
                                            }>All Currencies</a>
                                            {currencies.map((currencyOpt, index) =>
                                              <a className="dropdown-item" key={index} onClick={(e) => {
                                                e.preventDefault();
                                                setCurrency(currencyOpt.address);
                                                setRequestParams((prev) => ({ ...prev, currency: currencyOpt.address }))}
                                              }>{currencyOpt.text}</a>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="c-filter__price">
                                        <input placeholder="Min" type="number" onChange={(e) => setRequestParams((prev) => ({ ...prev, min_price: parseFloat(e.target.value) * (currentCurrency?.decimals || 1000000000000000000) }))}></input>
                                        <span className="label-to">to</span>
                                        <input placeholder="Max" type="number" onChange={(e) => setRequestParams((prev) => ({ ...prev, max_price: parseFloat(e.target.value) * (currentCurrency?.decimals || 1000000000000000000) }))}></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion c-filterItem" id="designFilter">
                                <div className="card">
                                  <div className="card-header" id="headingTwo">
                                    <button className="btn btn-link collapsed accordion-button" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Pets
                                    </button>
                                  </div>
                                  <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#designFilter">
                                    <div className="card-body c-filter__designs">
                                      <div className="search-area">
                                        <form action="#">
                                          <input type="text" placeholder="Search" onChange={(e) => setRequestParams((prev) => ({...prev, keyword: e.target.value}))}/>
                                          <button type="submit" onClick={(event) => event.preventDefault()}><i className="fas fa-search"></i></button>
                                        </form>
                                      </div>
                                      {(designs || []).map((design, index) =>
                                        <div className="c-designItem custom-checkbox" key={index}>
                                          <input type="checkbox" id={`design-${index}`} className="custom-control-input" value={design.id} onChange={selectDesignEvent}/>
                                          <label htmlFor={`design-${index}`} className="custom-control-label">{design.name}</label>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row col-lg-9 plan-list c-nftList">
                            <div className="row full-screen">
                              {requestParams?.page > 1 ? <></> :
                                (currentResult.length > 0 ?
                                  currentResult.map((nftItem, index) =>
                                    <NftItem key={index} nftItem={nftItem} designs={designs?.data} tiers={tiers?.data}/>
                                  ) : (nfts?.data?.length > 0 && !isLoading ? <></> : <div className="not-found">{isLoading ? "LOADING..." : "No Record"}</div>))
                              }
                              {nfts?.data?.length > 0 &&
                                (nfts?.data || []).map((nftItem, index) =>
                                  <NftItemInMarket key={index} nftItem={nftItem}/>
                                )}
                            </div>
                            {nfts?.data?.length > 30 &&
                              <div className="c-pagination">
                                <ReactPaginate pageCount={(nfts?.data?.length || 1) / 30} onPageChange={handlePageClick} pageRangeDisplayed={2} marginPagesDisplayed={1} breakLabel='...' forcePage={requestParams?.page - 1}/>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NftStoreIndex;
