import React from "react";
import { useWeb3React } from "@web3-react/core";

import Header from "../components/Header";
import Footer from "../components/Footer";
import PlayGame from "../components/PlayGame";
import SubscribeSection from "../components/SubscribeSection";
import WalletListPopup from "../components/WalletListPopup";
import HeaderForDashboard from "../components/HeaderForDashboard";

function PlayGamePage(props) {
  let {defaultWidth, height, width, i18n} = props;
  const { account } = useWeb3React();

  return (
    <div>
      {account ? <HeaderForDashboard {...props} currentRoute={"play-game"}/> : <Header height={height} width={width} ratio={width/defaultWidth} i18n={i18n} currentRoute={"play-game"}/>}
      <PlayGame />
      {account ? <></> : <SubscribeSection />}
      {account ? <></> : <Footer />}
      <WalletListPopup />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
    </div>
  );
}



export default PlayGamePage;
