import { isMobile } from "react-device-detect";

function NFTDetail(props) {
  const { nftInfo } = props;
  const titleUrl = `/assets/images/title/${nftInfo?.name ? nftInfo?.name?.replaceAll(" ", "-").toLowerCase() : ""}.png`;
  return (
    <section className="c-nftDetail__section">
      <div className="container">
        <div className="row justify-content-center">
          {!isMobile &&
            <div className="col-xs-12 col-lg-6">
              <div className="image">
                <img src={nftInfo?.image} alt="" className={nftInfo?.isHouse ? "nftImage is-nftHouse" : "nftImage"}/>
              </div>
            </div>
          }
          <div className="col-xs-12 col-lg-6">
            <div className="">
              <div className="title">
                <img src={titleUrl} alt={nftInfo?.name} className="titleImg"/>
              </div>
              <div className="subTitle">
                Obtainable via Season One Mystery Box
              </div>
              {isMobile && <div className="image">
                <img src={nftInfo?.image} alt="" className="nftImage"/>
              </div>}
              <div className="nftDescription">
                {nftInfo?.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NFTDetail;
