function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="flogo">
              <a href="#"><img src="/assets/images/logo.png" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-link"><a href="/privacy-policy" data-toggle="modal" data-target="#js-privacyPolicyPopup">Privacy Policy</a> | <a href="/terms-of-service" data-toggle="modal" data-target="#js-termsOfServicePopup">Terms of Service</a></div>
              <p>Copyright © 2021.All Rights Reserved By <span className="pink-text">SHIBAFRIEND</span></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
