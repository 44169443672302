import Metamask from "../icons/Metamask";
import MathWallet from "../icons/MathWallet";
import TokenPocket from "../icons/TokenPocket";
import TrustWallet from "../icons/TrustWallet";
import WalletConnect from "../icons/WalletConnect";
import BinanceChain from "../icons/BinanceChain";
import SafePalWallet from "../icons/SafePalWallet";
import {isMobile, isBrowser} from "react-device-detect";

export const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc",
}

export const connectors = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  },
  // {
  //   title: "TrustWallet",
  //   icon: TrustWallet,
  //   connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  // },
  // {
  //   title: "MathWallet",
  //   icon: MathWallet,
  //   connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  // },
  // {
  //   title: "TokenPocket",
  //   icon: TokenPocket,
  //   connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  // },
  // {
  //   title: "Binance Chain",
  //   icon: BinanceChain,
  //   connectorId: ConnectorNames.BSC,
  // },
  // {
  //   title: "SafePal",
  //   icon: SafePalWallet,
  //   connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  // },
];

export const connectorsForMobile = [
  // {
  //   title: "Metamask",
  //   icon: Metamask,
  //   connectorId: (isBrowser && isMobile) ? ConnectorNames.WalletConnect : ConnectorNames.Injected,
  // },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  }
];

export const connectorLocalStorageKey = "connectorId";
export const walletLocalStorageKey = "walletName";