import React, {useState} from "react";

import Popup from "../components/Popup";

function SectionFour(props) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [numberOfOwners, setNumberOfOwners] = useState(2799);
  return (
    <section className="ex-lottery ex-lottery2 slider-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h5 className="subtitle">
                Exclusive Mystery Box!
              </h5>
              <h2 className="title ">
                COLLECT NFT BUILDINGS AND EARN
              </h2>
              <h6 className="text">
                CAN'T BUY REAL ESTATE? BUY THESE INSTEAD!
              </h6>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12">
            <div className="ex-lottery-slider">
              <div className="slider-item" onClick={() => {
                setTitle("SHIBAFRIEND CASINO!");
                setBody(<p>
                  Players will play different types of Pokemon-style pet card games.<br /> 
                  Single-player and multiple players.<br />
                  There is no gambling element in the games.<br />
                  It is no-lost gameplay where the winner will win a limited edition of RARE NFT Card prizes.<br />
                  NFT Staker will earn daily profits from training fees.</p>);
                setImageUrl("/assets/images/product/singapore-mbs.jpg");
                setNumberOfOwners(3759);
                window.$("#js-popup").modal("show");
              }}>
                <div className="single-tikit">
                  <div className="image">
                    <span className="tag">Ending Soon</span>
                    <img src="/assets/images/product/singapore-mbs.jpg" alt="" />
                  </div>
                  <div className="content">
                    <div className="top-area">
                      <div className="top-bar">
                        <div className="progress-bar"></div>
                        <div className="main-bar"></div>
                      </div>
                    </div>
                    <div className="content-area">
                      <h4>SHIBAFRIEND CASINO</h4>
                      <div className="c-bottom">
                        <a href="#" className="mybtn1">BUY NOW!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item" onClick={() => {
                setTitle("SHIBAFRIEND SPORT HUB!");
                setBody(<p>
                  Players will play different types of Sports-style game competitions. <br />
                  Weekly and Monthly Competition. <br />
                  Gameplay includes speedboat, running and swimming contests. <br />
                  Single-player and multiple players.<br />
                  It is a no lost pool contest prize.<br />
                  NFT Staker will earn daily profits from training fees.</p>);
                setImageUrl("/assets/images/product/singapore-stadium.jpg");
                setNumberOfOwners(4977);
                window.$("#js-popup").modal("show");
              }}>
                <div className="single-tikit">
                  <div className="image">
                    <span className="tag">Ending Soon</span>
                    <img src="/assets/images/product/singapore-stadium.jpg" alt="" />
                  </div>
                  <div className="content">
                    <div className="top-area">
                      <div className="top-bar">
                        <div className="progress-bar"></div>
                        <div className="main-bar"></div>
                      </div>
                    </div>
                    <div className="content-area">
                      <h4>SHIBAFRIEND SPORT HUB</h4>
                      <div className="c-bottom">
                        <a href="#" className="mybtn1">BUY NOW!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item" onClick={() => {
                setTitle("SHIBAFRIEND AIRPORT!");
                setBody(<p>
                  Single-player and multiple players.<br />
                  Gameplay includes flying games, space travels.<br />
                  Increase pet energy levels.<br />
                  Increase active levels.<br />
                  Unlock Competition qualification level.<br /> 
                  Compulsory gameplay.<br />
                  NFT Staker will earn daily profits from training fees.</p>);
                setImageUrl("/assets/images/product/singapore-airport.jpg");
                setNumberOfOwners(9659);
                window.$("#js-popup").modal("show");
              }}>
                <div className="single-tikit">
                  <div className="image">
                    <span className="tag">Ending Soon</span>
                    <img src="/assets/images/product/singapore-airport.jpg" alt="" />
                  </div>
                  <div className="content">
                    <div className="top-area">
                      <div className="top-bar">
                        <div className="progress-bar"></div>
                        <div className="main-bar"></div>
                      </div>
                    </div>
                    <div className="content-area">
                      <h4>SHIBAFRIEND AIRPORT</h4>
                      <div className="c-bottom">
                        <a href="#" className="mybtn1">BUY NOW!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12">
            <div className="section-footer">
              HOSPITAL, UNIVERSITY, CASINO, SPORTS HUB, EXPO AND AIRPORT are our Season One NFTs. <br />
              Players are required to go to these 6 contest venues to play multiple games to earn NFT drops and SFT coins. <br />
              The contests and gameplays are explained in each of these venues.  Players are required to pay entry fees to join the contests.<br />
              Be the first to collect all 6 venues.  Buy our mystery box from $20 BNB / BUSD and win 1 of these  6 venues.
            </div>
          </div>
        </div>
      </div>
      <Popup title={title} body={body} imageUrl={imageUrl} numberOfOwners={numberOfOwners}/>
    </section>
  );
}

export default SectionFour;
