import React, {useState} from "react";
import {ethers} from "ethers";
import numeral from "numeral";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";


import { contractConfig } from "../config/contract";

function UserStakePlan(props) {
  const { account } = useWeb3React();
  let { userStake, isEnd, isTransaction, success } = props;
  const {plan} = userStake;
  console.log(userStake);
  const [isLoading, setIsLoading] = useState(false);
  const secondADay = process.env.REACT_APP_PERIOD_VALUE * 60 * 1000;
  

  async function withdraw(event) {
    event.preventDefault();
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    setIsLoading(true);
    let stakeContract = new ethers.Contract(contractConfig.shfStakingContract.address, contractConfig.shfStakingContract.abi, signer);
    const res = await stakeContract.withdraw(userStake.stakeId);
    await provider.waitForTransaction(res.hash);
    setIsLoading(false);
    success("Withdrawed successfully!");
  }

  return (
    <div className="row stake-plan-item user-stake-item">
      <div className="col-lg-1"><img alt="" src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon-small" /></div>
      <div className="col-lg-2">
        <div className="stake-plan-head">STAKE SFT {plan?.period?.toString()}</div>
        <div className="stake-plan">SFT POOL</div>
      </div>
      <div className="col-lg-3">
        <div className="stake-plan">POOL AMOUNT</div>
        <div className="stake-plan">{numeral(ethers.utils.formatUnits(parseFloat(userStake?.amount?.toString()), 9)).format("0,0")}</div>
      </div>
      <div className={isTransaction ? "col-lg-3" : "col-lg-2"}>
        <div className="stake-plan">SFT EARNED</div>
        <div className="stake-plan">{numeral(ethers.utils.formatUnits(parseFloat(userStake?.amount?.toString()) * parseFloat(plan?.rate?.toString()) / 100, 9)).format("0,0")}</div>
      </div>
      <div className={isTransaction ? "col-lg-3" : "col-lg-1"}>
        <div className="stake-plan">APR</div>
        <div className="stake-plan">{plan?.rate?.toString()}%</div>
      </div>
      {isEnd ? (isTransaction ? null :
        <div className="col-lg-3">
          <a href="#" className="mybtn1" onClick={withdraw}>{isLoading ? "Loading..." : "Withdraw"}</a>
        </div>)
        :
        <div className="col-lg-3">
          <div className="stake-plan">Ends in</div>
          <div className="stake-plan">
            <Countdown
              date={parseFloat(userStake?.stakeTime?.toString()) * 1000 + secondADay * parseInt(plan?.period?.toString())}
              renderer={(options) => {
                return `${options.days}d ${options.hours}h ${options.minutes}m ${options.seconds}s`;
              }}
            /></div>
        </div>
      }
    </div>
  );
}

export default UserStakePlan;
