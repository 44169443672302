import React from "react";
import numeral from "numeral";
import "@google/model-viewer";
import { s3Url } from "hooks/useFetchOption";


function NftDetail({ nftItem }) {
  const model_url = s3Url + nftItem?.design?.name?.replaceAll(" ", "_") + ".glb";

  return (
    <div className="c-nftItem">
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem?.name}</div>
        <model-viewer class="marketplace-viewer" src={model_url} camera-controls shadow-intensity="1">
          <div className="lazy-load-poster" slot="poster">Loading...</div>
        </model-viewer>
        <div className="c-nftItem__batchName">
          {nftItem?.design?.name}
        </div>
        <div className="c-nftItem__tier">
          {nftItem?.tierInfo?.name}
        </div>
        {nftItem?.price &&
        <div className="c-nftItem__buttons">
          <div className=""><span className="">${numeral(nftItem?.price).format("0,0")}</span></div>
        </div>
        }
      </div>
    </div>
  );
}

export default NftDetail;
