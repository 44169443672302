import "@google/model-viewer";
import {isMobile} from "react-device-detect";

function ShibaNFTPet({width}) {
  return (
    <section className="c-nftPet__section">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-7 left-content">
            <div className="head">
              <img src="/assets/images/home/nft-pet/head.png" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 right-content">
            <div className="head">
              <img src="/assets/images/home/nft-pet/sub-head.png" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="description">
              <img src="/assets/images/home/nft-pet/description.png" alt="" />
            </div>
            { width > 768 && 
              <div className="">
                <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Footballer.glb" camera-controls shadow-intensity="1">
                  <div className="lazy-load-poster" slot="poster">Loading...</div>
                </model-viewer>
                <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Referee.glb" camera-controls shadow-intensity="1">
                  <div className="lazy-load-poster" slot="poster">Loading...</div>
                </model-viewer>
              </div>
            }
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div className="">
              <img src="/assets/images/home/nft-pet/phones.png" alt="" />
            </div>
          </div>
          { width <= 768 && 
            <div className="col-xl-7 col-lg-7 col-md-7">
              <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Footballer.glb" camera-controls shadow-intensity="1">
                <div className="lazy-load-poster" slot="poster">Loading...</div>
              </model-viewer>
              <model-viewer class="model-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Referee.glb" camera-controls shadow-intensity="1">
                <div className="lazy-load-poster" slot="poster">Loading...</div>
              </model-viewer>
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default ShibaNFTPet;
