import React, {useMemo} from "react";
import numeral from "numeral";
import { currencies } from "../../config/currency";
import { useNavigate } from "react-router-dom";
import "@google/model-viewer";
import { s3Url } from "hooks/useFetchOption";


function NftItem({ nftItem, buyEvent = () => {} }) {
  const navigate = useNavigate();
  const currentCurrency = useMemo(
    () => currencies.find((u) => u.address.toLowerCase() === nftItem?.currency?.toLowerCase()),
    [nftItem]
  );
  const model_url = s3Url + nftItem?.design?.name?.replaceAll(" ", "_") + ".glb";

  return (
    <div className="col-lg-4 c-nftItem" onClick={() => navigate(`/marketplace/nft/${nftItem.token_id}`)}>
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem.name}</div>
        <div className="c-nftItem__image">
          <model-viewer class="marketplace-viewer" src={model_url} camera-controls shadow-intensity="1" />
        </div>
        <div className="c-nftItem__batchName">
          {nftItem.batch_name}
        </div>
        <div className="c-nftItem__tier">
          {nftItem.tier}
        </div>
        {nftItem?.price &&
        <div className="row c-nftItem__buttons">
          <div className="c-nftItem__price col-6"><span className="">${numeral(parseFloat(nftItem.price)).format("0,0")}</span></div>
          <div className="c-nftItem__buyBtn col-6"><a href={`/marketplace/nft/${nftItem.token_id}`} className="mybtn1 c-nftItem__btnBuy">Buy</a></div>
        </div>
        }
      </div>
    </div>
  );
}

export default NftItem;
