import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { ethers } from "ethers";
const POLLING_INTERVAL = 12000;
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const injected = new InjectedConnector({ supportedChainIds: [1, 56] });
const walletconnect = new WalletConnectConnector({
  rpc: { 1: "https://mainnet.infura.io/v3/818f0cc4092b4e0a8a041ddf4f2ad91f", 56: "https://mainnet.infura.io/v3/818f0cc4092b4e0a8a041ddf4f2ad91f" },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});
const bscConnector = new BscConnector({ supportedChainIds: [1, 56] });
export const connectorsByName = {
  ["injected"]: injected,
  ["walletconnect"]: walletconnect,
  ["bsc"]: bscConnector,
};
export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider, account, message) => {
  var _a, _b;
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message);
    return signature;
  }
  /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
  if ((_a = provider.provider) === null || _a === void 0 ? void 0 : _a.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
    const signature = await ((_b = provider.provider) === null || _b === void 0 ? void 0 : _b.wc.signPersonalMessage([wcMessage, account]));
    return signature;
  }
  return provider.getSigner(account).signMessage(message);
};
