function SubscribeSection(props) {
  return (
    <div className="subscribe-area subscribe-area2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="subscribe-box">
              <img className="left" src="assets/images/vr.png" alt="" />
              <img className="right" src="assets/images/game-controler.png" alt="" />
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="heading-area">
                    <h5 className="sub-title">
                        Register our Shibafriend Pet Social Media Platform!
                    </h5>
                    <h4 className="title">
                        To Get Exclusive Benefits
                    </h4>
                  </div>
                </div>
              
                <div className="col-lg-6 col-12">
                  <form action="#" className="form-area">
                    <a href="https://shibafriend.com" target="_blank" rel="noreferrer" className="mybtn1">Sign Up!
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeSection;
