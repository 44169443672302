import React, {useState, useEffect} from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import numeral from "numeral";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import "react-toastify/dist/ReactToastify.css";

import StakePlan from "./StakePlan";
import ConnectWallet from "./ConnectWallet";
import { contractConfig } from "../config/contract";

function TokenPoolDetail(props) {
  const { account } = useWeb3React();
  const [plans, setPlans] = useState([]);
  const [balance, setBalance] = useState(0);
  
  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  async function connect() {
    try {
      const [,ethers,signer] = await initProvider();

      const shfStakingContract = new ethers.Contract(contractConfig.shfStakingContract.address, contractConfig.shfStakingContract.abi, signer);
      console.log(shfStakingContract);
      const shfContract = new ethers.Contract(contractConfig.shfContract.address, contractConfig.shfContract.abi, signer);

      let plans = await shfStakingContract.getAllPlans();
      console.log(plans);
      setPlans(plans);
      const amount = await shfContract.balanceOf(await signer.getAddress());
      setBalance(ethers.utils.formatUnits(amount, 9));
     
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    if(account) connect();
  }, [account]);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              {account ?
                <div className="main-box wallet-box">
                  <div className="header-area">
                    <h4>SFT STAKING</h4>
                  </div>
                  <div className="wallet-tab-content"  id="pills-tabContent">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                        <div className="dipo-box">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="current-balance">
                                <p>COIN</p>
                                <h4>
                                  {numeral(balance).format("0,0")} <span>SFT</span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-8 plan-list">
                              {plans.map((plan, index) => 
                                <StakePlan key={index} plan={plan} planId={index} currentBalance={balance}/>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <ConnectWallet />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TokenPoolDetail;
