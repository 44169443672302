import React, {useState, useCallback, useMemo} from "react";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { gameToken } from "../../config/global";
import { contractConfig } from "../../config/contract";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

function ReferralClaimable({ referralData }) {
  const { account } = useWeb3React();
  const accessToken = window.localStorage.getItem(gameToken);
  const [sftLoading, setSftLoading] = useState(false);
  const [ethLoading, setEthLoading] = useState(false);
  const [shibLoading, setShibLoading] = useState(false);
  const [claimed, setClaimed] = useState(referralData?.totalAmountGenRefCode);
  const [sftEarning, setSftEarning] = useState(referralData?.sftEarning?.toString() || 0);
  const [ethEarning, setEthEarning] = useState(referralData?.ethEarning?.toString() || 0);
  const [shibEarning, setShibEarning] = useState(referralData?.shibEarning?.toString() || 0);

  useMemo(() => {
    const claimAmount = new BigNumber(referralData?.sftEarning?.toString());
    setSftEarning(claimAmount);
  }, [referralData?.sftEarning]);

  useMemo(() => {
    const claimAmount = new BigNumber(referralData?.ethEarning?.toString());
    setEthEarning(claimAmount);
  }, [referralData?.ethEarning]);

  useMemo(() => {
    const claimAmount = new BigNumber(referralData?.shibEarning?.toString());
    setShibEarning(claimAmount);
  }, [referralData?.shibEarning]);

  const claimSftEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (sftLoading || isNaN(sftEarning) || parseFloat(sftEarning) <= 0) {
        return true;
      }

      setSftLoading(true);
      const [provider,ethers,signer] = await initProvider();
      const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
      const sftAddress = await storeContract.sftAddress();
      console.log("Claiming...");
      console.log(storeContract);
      const approve = await storeContract.userClaim(sftAddress);

      await provider.waitForTransaction(approve.hash);
      toast("Claimed!");
      setSftEarning(0);
      setSftLoading(false);
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setSftLoading(false);
    }
  }, [sftLoading, account, accessToken, sftEarning]);

  const claimEthEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (ethLoading || isNaN(ethEarning) || parseFloat(ethEarning) <= 0) {
        return true;
      }

      setEthLoading(true);
      const [provider,ethers,signer] = await initProvider();
      const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
      console.log("Claiming...");
      console.log(storeContract);
      const approve = await storeContract.userClaim('0x0000000000000000000000000000000000000000');

      await provider.waitForTransaction(approve.hash);
      toast("Claimed!");
      setEthEarning(0);
      setEthLoading(false);
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setEthLoading(false);
    }
  }, [ethLoading, account, accessToken, ethEarning]);

  const claimShibEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (shibLoading || isNaN(shibEarning) || parseFloat(shibEarning) <= 0) {
        return true;
      }

      setShibLoading(true);
      const [provider,ethers,signer] = await initProvider();
      const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
      const shibAddress = await storeContract.shibAddress();
      console.log("Claiming...");
      console.log(storeContract);
      const approve = await storeContract.userClaim(shibAddress);

      await provider.waitForTransaction(approve.hash);
      toast("Claimed!");
      setShibEarning(0);
      setShibLoading(false);
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setShibLoading(false);
    }
  }, [shibLoading, account, accessToken, shibEarning]);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  return (
    <div className="row stake-plan-item">
      <div className="stake-plan-item--child stake-plan-head">NFT AFFILIATE INCOME</div>
      <div className="row stake-plan-item--child">
        <div className="col-lg-3"><img alt=""src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon" /></div>
        <div className="col-lg-9">
          <div className="stake-plan-head">SFT AFFILIATE INCOME</div>
          <div className="">Available to Claim: <span className="pink-text no-shadow">{numeral(new BigNumber(sftEarning).dividedBy(1000000000).toString()).format("0,0.0000")}</span></div>
          <div className="stake-plan-btn">
            {<a className="custom-btn-v2" onClick={claimSftEvent} disabled={isNaN(sftEarning) || parseFloat(sftEarning) <= 0}>{sftLoading ? "Loading..." : "Claim"}</a>}
          </div>
        </div>
      </div>

      <div className="row stake-plan-item--child">
        <div className="col-lg-3"><img alt=""src="/assets/images/eth-logo.png" className="stake-plan-icon" /></div>
        <div className="col-lg-9">
          <div className="stake-plan-head">ETH AFFILIATE INCOME</div>
          <div className="">Available to Claim: <span className="pink-text no-shadow">{numeral(new BigNumber(ethEarning).dividedBy(1000000000000000000).toString()).format("0,0.0000")}</span></div>
          <div className="stake-plan-btn">
            {<a className="custom-btn-v2" onClick={claimEthEvent} disabled={isNaN(ethEarning) || parseFloat(ethEarning) <= 0}>{ethLoading ? "Loading..." : "Claim"}</a>}
          </div>
        </div>
      </div>

      <div className="row stake-plan-item--child">
        <div className="col-lg-3"><img alt=""src="/assets/images/shib-logo.png" className="stake-plan-icon" /></div>
        <div className="col-lg-9">
          <div className="stake-plan-head">SHIB AFFILIATE INCOME</div>
          <div className="">Available to Claim: <span className="pink-text no-shadow">{numeral(new BigNumber(shibEarning).dividedBy(1000000000000000000).toString()).format("0,0.0000")}</span></div>
          <div className="stake-plan-btn">
            {<a className="custom-btn-v2" onClick={claimShibEvent} disabled={isNaN(shibEarning) || parseFloat(shibEarning) <= 0}>{shibLoading ? "Loading..." : "Claim"}</a>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralClaimable;
