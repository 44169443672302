import React, {useState, useEffect} from "react";
import { toast, ToastContainer } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import UserStakePlan from "./UserStakePlan";
import ConnectWallet from "./ConnectWallet";
import { contractConfig } from "../config/contract";

function Overview() {
  const { account } = useWeb3React();
  const [liveUserStakes, setLiveUserStakes] = useState([]);
  const [endedUserStakes, setEndedUserStakes] = useState([]);
  const [plans, setPlans] = useState([]);

  const secondADay = process.env.REACT_APP_PERIOD_VALUE * 60 * 1000;

  async function initStakeContract() {
    const [,ethers,signer] = await initProvider();
    let contract =  new ethers.Contract(contractConfig.shfStakingContract.address,contractConfig.shfStakingContract.abi,signer);
    return contract;
  }
  async function initProvider(){
    const provider =  new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }
  async function getData() {
    const stakeContract = await initStakeContract();
    let userStakePlans = await stakeContract.userStake();
    let stakePlans = await stakeContract.getAllPlans();
    userStakePlans = userStakePlans.map((item, index) => ({
      ...item,
      stakeId: index,
      plan: stakePlans[item.planId]
    }));
    let liveStakes = userStakePlans.filter((item) => item.stakeTime * 1000 + secondADay * parseInt(stakePlans[item.planId].period) > Date.now() && item.status === 1);
    let endStakes = userStakePlans.filter((item) => item.stakeTime * 1000 + secondADay * parseInt(stakePlans[item.planId].period) <= Date.now());
    // let transactionStakes = userStakePlans.filter((item) => parseInt(item.status) === 0);
    setPlans(stakePlans);
    console.log(userStakePlans);
    console.log(stakePlans);
    setLiveUserStakes(liveStakes);
    console.log(endStakes.length);
    setEndedUserStakes(endStakes);
    // setTransactions(transactionStakes);
  }

  async function connect() {
    try {
      await getData();
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    connect();
  }, [account]);

  function planFor(planId) {
    return plans[planId];
  }

  async function getTransactions() {
    const [provider,ethers,signer] = initProvider();
    let block = await provider.getBlock("latest");
    let trans = [];
    for (let txHash of block.transactions) {
      try {
        let tran = await provider.eth.getTransaction(txHash);
        if (tran.to === signer.address) {
          trans.push(tran);
        }
      } catch {
        //???
      }
    }
    setTransactions(trans);
  }

  async function success(message) {
    await getData();
    toast(message);
  }

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              { account ?
                <div className="main-box wallet-box">
                  <div className="header-area">
                    <h4>OVERVIEW</h4>
                  </div>
                  <div className="wallet-tab-content"  id="pills-tabContent">
                    <div className="col-lg-12 plan-list">
                      <div className="wallet-tab-menu">
                        <ul className="nav" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="pills-wt1-tab" data-toggle="pill" href="#pills-wt1" role="tab" aria-controls="pills-wt1">Live</a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-wt2-tab" data-toggle="pill" href="#pills-wt2" role="tab" aria-controls="pills-wt2">End</a>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-wt3-tab" data-toggle="pill" href="#pills-wt3" role="tab" aria-controls="pills-wt3">Transactions</a>
                          </li> */}
                        </ul>
                      </div>
                      <div className="wallet-tab-content" id="pills-tabContent">
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                            {liveUserStakes.length > 0 ?
                              <div>
                                {liveUserStakes.map((userStake, index) =>
                                  <UserStakePlan key={index} userStake={userStake} plan={planFor(userStake.planId)} isEnd={false} success={(message) => success(message)}/>
                                )}
                              </div> : <div className="not-found">No Record</div>
                            }
                          </div>
                          <div className="tab-pane fade" id="pills-wt2" role="tabpanel" aria-labelledby="pills-wt2-tab">
                            {endedUserStakes.length > 0 ?
                              <div className="">
                                {endedUserStakes.map((userStake, index) =>
                                  <UserStakePlan key={index} userStake={userStake} plan={planFor(userStake.planId)} isEnd={true} isTransaction={userStake?.status === 0} success={(message) => success(message)}/>
                                )}
                              </div> : <div className="not-found">No Record</div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <ConnectWallet />
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default Overview;
