import React, {useState, useEffect, useMemo, useCallback} from "react";
import {useParams} from "react-router";
import { ToastContainer } from "react-toastify";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import "../../assets/css/marketplace.css";

import { parameterize } from "../../utils/helper";
import { apiMarketUrl } from "../../hooks/useFetchOption";
import { currencies } from "../../config/currency";
import { fetchWithConfig } from "../../utils/helper";
import { contractConfig } from "../../config/contract";

import HeaderForDashboard from "../../components/HeaderForDashboard";
import NftStoreDetailComponent from "../../components/NftStore/Detail";
import WalletListPopup from "../../components/WalletListPopup";
import md5 from "md5";

function NftStoreDetail(props) {
  const { account } = useWeb3React();
  const { handle } = useParams("");
  const [nftItem, setNftItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const endpointDesign = useMemo(
    () => `${apiMarketUrl}/public/designs?per_page=100`,
    []
  );

  const endpointTiers = useMemo(
    () => `${apiMarketUrl}/public/tiers`,
    []
  );

  useEffect(async () => {
    setIsLoading(true);
    let designsRes = await fetchWithConfig(endpointDesign, {});
    let tiersRes = await fetchWithConfig(endpointTiers, {});

    const [,ethers,signer] = await initProvider();

    const storeContract = new ethers.Contract(contractConfig.storeContractBNB.address, contractConfig.storeContractBNB.abi, signer);
    let result = await storeContract.getAllSales();
    result = result.map((item, index) => {
      let design = (designsRes?.data || []).find((design) => design.id.toString() === parseInt(item[4]).toString());
      let tier = (tiersRes?.data || []).find((tier) => tier.id.toString() === parseInt(item[5]).toString());
      let name = `${design?.name}#${tier?.name}`;
      let handle = parameterize(`${design?.name} ${tier?.name} ${md5(index)}`);
      console.log(handle);
      return {
        ...item,
        id: index,
        batchId: parseInt(item[4]),
        tier: parseInt(item[5]),
        price: parseFloat(item[1]),
        status: parseInt(item[3]),
        handle: handle,
        design: design,
        tierInfo: tier,
        name: name
      };
    });
    setNftItem(result.find((item) => item.handle === handle));
    setIsLoading(false);
  }, [currencies, handle]);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  return (
    <div>
      <HeaderForDashboard currentRoute={"marketplace"} {...props}/>
      <NftStoreDetailComponent nftItem={nftItem} isLoading={isLoading}/>
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default NftStoreDetail;
