import React from "react";

function ResetPasswordPopup({ newPassword }) {
  return (
    <div className="modal fade popup" id="js-resetPasswordPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text"></h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            <div className="c-sellPopup__row">
              Your password was reset!
            </div>
            <div className="c-sellPopup__row">
              Your new password is {newPassword}
            </div>
            <div className="sell-popup-actions"><a className="mybtn1" data-dismiss="modal" aria-label="Close">Close</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPopup;
