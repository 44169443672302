function NFTInformation(props) {
  return (
    <section className="c-nftInfo__section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <img src="/assets/images/home/nft-information/head.png" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="">
              <div className="image">
                <img src="/assets/images/home/nft-information/shiba-sport.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="">
              <div className="image">
                <img src="/assets/images/home/nft-information/shiba-nft.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="">
              <div className="image">
                <img src="/assets/images/home/nft-information/shiba-metaverse.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NFTInformation;
