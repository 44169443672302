import React, {useState, useEffect, useMemo} from "react";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import Diamond from "./Token/Diamond";
import PrivateSale from "./Token/PrivateSale";
import PreIdo from "./Token/PreIdo";
import ReferralClaimable from "./Token/ReferralClaimable";
import UnclaimedPopup from "./Token/UnclaimedPopup";
import ConnectWallet from "./ConnectWallet";
import { gameToken } from "../config/global";
import { apiMarketUrl } from "../hooks/useFetchOption";
import useAuthenticatedSwr from "../hooks/useAuthenticatedSwr";
import { contractConfig } from "../config/contract";


function InvestorDetail() {
  const { account } = useWeb3React();
  const [balance, setBalance] = useState(0);
  const [diamondBalance, setDiamondBalance] = useState(0);
  const accessToken = window.localStorage.getItem(gameToken);
  const [referralData, setReferralData] = useState({});
  const [privateSaleInfo, setPrivateSaleInfo] = useState({totalTokenAmount: 0});
  const [privateSaleATC, setPrivateSaleATC] = useState(0);
  const [preIdoInfo, setPreIdoInfo] = useState({totalTokenAmount: 0});
  const [preIdoATC, setPreIdoATC] = useState(0);
  const endpoint = useMemo(
    () => "/game/claimable",
    []
  );

  const endpointUnclaimed = useMemo(
    () => "/game/claims",
    []
  );

  const { data: diamond, error } = useAuthenticatedSwr(endpoint, { headers: { Authorization: `Bearer ${accessToken}`,} }, apiMarketUrl);
  const { data: unclaimeds, unclaimError } = useAuthenticatedSwr(endpointUnclaimed, { headers: { Authorization: `Bearer ${accessToken}`} }, apiMarketUrl);
  
  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  useEffect(async () => {
    async function prepareData(){
      const [,ethers,signer] = await initProvider();
      try {
        if(signer){
          const address = await signer.getAddress();
          const shfContract = new ethers.Contract(contractConfig.shfContract.address, contractConfig.shfContract.abi, signer);

          let shfbalance = await shfContract.balanceOf(address);
          console.log('shfbalance', shfbalance.toString());
          shfbalance = new BigNumber(shfbalance.toString());
          setBalance(shfbalance.dividedBy(1000000000));
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getReferralData() {
      const [,ethers,signer] = await initProvider();
      try {
        if (signer){
          const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
          const refData = await storeContract.getAffiliateInfor();
          console.log(storeContract);
          console.log(refData?.sftEarning?.toString());
          setReferralData(refData);
        }
      } catch (error) {
        console.log(error);
        setReferralData({});
      }
    }

    async function getPrivateSaleData() {
      const [,ethers,signer] = await initProvider();
      try {
        if (signer){
          const privateSaleContract = new ethers.Contract(contractConfig.privateSaleContract.address, contractConfig.privateSaleContract.abi, signer);
          const privateInfo = await privateSaleContract.getInfo();
          const privateATC = await privateSaleContract.getClaimable();
          console.log(privateInfo);
          console.log(privateATC);
          setPrivateSaleInfo(privateInfo);
          setPrivateSaleATC(privateATC.toString());
        }
      } catch (error) {
        console.log(error);
        setPrivateSaleInfo({totalTokenAmount: 0});
        setPrivateSaleATC(0);
      }
    }

    async function getPreIdoData() {
      const [,ethers,signer] = await initProvider();
      try {
        if (signer){
          const preIdoContract = new ethers.Contract(contractConfig.preIdoContract.address, contractConfig.preIdoContract.abi, signer);
          const preIdoInfomartion = await preIdoContract.getInfo();
          const preidoClaimable = await preIdoContract.getClaimable();
          console.log(preIdoInfomartion);
          console.log(preidoClaimable);
          setPreIdoInfo(preIdoInfomartion);
          setPreIdoATC(preidoClaimable.toString());
        }
      } catch (error) {
        console.log(error);
        setPrivateSaleInfo({totalTokenAmount: 0});
        setPrivateSaleATC(0);
      }
    }

    if (account) {
      await prepareData();
      await getReferralData();
      await getPrivateSaleData();
      await getPreIdoData();
    }

  },[account]);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              {account ?
                <div className="main-box wallet-box">
                  <div className="header-area">
                    <h4>TOKEN</h4>
                  </div>
                  <div className="wallet-tab-content"  id="pills-tabContent">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                        <div className="dipo-box">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="current-balance">
                                <p>COIN IN WALLET</p>
                                <h4>
                                  {numeral(balance).format("0,0.0000")} <span>SFT</span>
                                </h4>
                                <h4>
                                  {diamondBalance} <span>DIAMOND</span>
                                </h4>
                              </div>
                            </div>


                            <div className="col-lg-8 plan-list">
                              {privateSaleInfo?.totalTokenAmount?.toString() !== "0" && privateSaleInfo?.totalTokenAmount?.toString() !== "" && <PrivateSale privateSaleInfo={privateSaleInfo} privateSaleATC={privateSaleATC}/>}
                              {preIdoInfo?.totalTokenAmount?.toString() !== "0" && preIdoInfo?.totalTokenAmount?.toString() !== "" && <PreIdo preIdoInfo={preIdoInfo} preIdoATC={preIdoATC}/>}
                              {diamond && diamond?.data && <Diamond diamond={diamond?.data} />}
                              {<ReferralClaimable referralData={referralData} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <ConnectWallet />}
            </div>
          </div>
        </div>
      </div>
      <UnclaimedPopup unclaimeds={unclaimeds?.data}/>
    </section>
  );
}

export default InvestorDetail;
