import React, {useEffect, useState} from "react"
import {isMobile} from "react-device-detect";
import useAuth from "../hooks/useAuth"
import { connectors, connectorsForMobile } from "./wallet/Config"
import { connectorLocalStorageKey, walletLocalStorageKey } from "../components/wallet/Config"

function WalletListPopup(props) {
  const { login } = useAuth();
  const [isMore, setIsMore] = useState(false)
  const [wallets, setWallets] = useState([])
  const [activeIndex, setActiveIndex] = useState(positionActiveWallet())

  function connect(event, walletItem) {
    event.preventDefault();
    login(walletItem.connectorId);
    window.localStorage.setItem(connectorLocalStorageKey, walletItem.connectorId)
    window.localStorage.setItem(walletLocalStorageKey, walletItem.title)
    window.$("#js-walletPopup").modal("hide");
  }

  function moveActiveWalletToFirstPosition() {
    let result = connectors;
    result.splice(0, 0, result.splice(positionActiveWallet(), 1)[0]);
    return result;
  }

  function positionActiveWallet() {
    let activeWallet = window.localStorage.getItem(walletLocalStorageKey)
    if (activeWallet) {
      return connectors.findIndex((item) => item.title === activeWallet )
    } else {
      return 0
    }
  }

  useEffect(() => {
    if (isMore) {
      setWallets(moveActiveWalletToFirstPosition());
    } else {
      setWallets(moveActiveWalletToFirstPosition().slice(0, 3));
    }
  }, [isMore, activeIndex])

  useEffect(() => {
    window.$("#js-walletPopup").on("show.bs.modal", () => {
      setIsMore(false);
    })
  }, [])

  return (
    <div className="modal fade popup" id="js-walletPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text">Connect Wallet</h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            <div className={isMobile ? "row is-mobile" : "row"}>
              {(isMobile ? connectorsForMobile : wallets).map((walletItem, key) =>
                <div className="col-lg-6 wallet-item col-xs-12" key={key}>
                  <a onClick={(event) => {
                    connect(event, walletItem);
                  }} href="#">
                    {<walletItem.icon width={"100px"} color="text"/>}
                    <span className="wallet-label">{walletItem.title}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletListPopup;
