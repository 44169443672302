import React, {useState, useMemo} from "react";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { toast } from "react-toastify";
import { contractConfig } from "../../config/contract";

function PreIdo({ preIdoInfo, preIdoATC }) {
  const { account } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [lockedTokens, setLockedTokens] = useState(0);
  const [availableToClaim, setAvailableToClaim] = useState(preIdoATC);
  const [info, setInfo] = useState(preIdoInfo);

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  async function claimEvent(event) {
    event.preventDefault();

    if (isLoading || preIdoATC === "0" || availableToClaim === "0") {
      return false;
    }
    setIsLoading(true);

    const [provider,ethers,signer] = await initProvider();

    const preIdoContract = new ethers.Contract(contractConfig.preIdoContract.address, contractConfig.preIdoContract.abi, signer);

    try {
      let response = await preIdoContract.userClaim();
      await provider.waitForTransaction(response.hash);
      toast("Claimed!");
      setAvailableToClaim("0");
      const privateInfo = await preIdoContract.getInfo();
      setInfo(privateInfo);
      setIsLoading(false);
    } catch (ex) {
      console.log(ex);
      toast("Claim Failed! Please try again!");
      setIsLoading(false);
    }
  }

  useMemo(async () => {
    let lockedToken = info?.totalTokenAmount - info?.claimedTokenAmount;
    setLockedTokens(lockedToken);
  }, [info?.totalTokenAmount, info?.claimedTokenAmount]);

  useMemo(async () => {
    setInfo(preIdoInfo);
  }, [preIdoInfo?.totalTokenAmount, preIdoInfo?.claimedTokenAmount]);

  useMemo(async () => {
    setAvailableToClaim(preIdoATC);
  }, [preIdoATC]);

  return (
    <div className="row stake-plan-item">
      <div className="col-lg-3"><img src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon" /></div>
      <div className="col-lg-9">
        <div className="stake-plan-head">SFT IDO VESTING</div>
        <div className="">Total: <span className="blue-text no-shadow">{numeral(new BigNumber(info?.totalTokenAmount?.toString()).dividedBy(1000000000).toString()).format("0,0.0000")}</span></div>
        <div className="">Locked Token: <span className="blue-text no-shadow">{numeral(new BigNumber(lockedTokens).dividedBy(1000000000).toString()).format("0,0.0000")}</span></div>
        <div className="">Claimed: <span className="blue-text no-shadow">{numeral(new BigNumber(info?.claimedTokenAmount?.toString()).dividedBy(1000000000).toString()).format("0,0.0000")}</span></div>
        <div className="">Available to Claim: <span className="pink-text no-shadow">{numeral(new BigNumber(availableToClaim).dividedBy(1000000000).toString()).format("0,0.0000")}</span></div>
        <div className="stake-plan-btn">
          <a className="custom-btn-v2" disabled={preIdoATC === "0" || preIdoATC === "" || availableToClaim === "0" || availableToClaim === ""} onClick={claimEvent}>{isLoading ? "Loading..." : "Claim"}</a>
        </div>
      </div>
    </div>
  );
}

export default PreIdo;
