import React, {useState, useMemo, useCallback, useEffect, useRef} from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import { contractConfig } from "../../config/contract";
import { isMobile } from "react-device-detect";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@google/model-viewer";
import { storeCurrencies } from "../../config/currency";
import { apiMarketUrl } from "../../hooks/useFetchOption";
import useAuthenticatedSwr from "../../hooks/useAuthenticatedSwr";
import RollResultPopup from "./RollResultPopup";
import NFTDetail from "./NFTDetail";

function ClawMachine({width, height, ratio}) {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState("SFT");
  const [quantity, setQuantity] = useState(1);
  const [referrerCode, setReferrerCode] = useState("0");
  const [sftAddress, setSftAddress] = useState("0x069616D68AE528a27fe99584401637D5C7Eb4606");
  const [shibAddress, setShibAddress] = useState("0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce");
  const [shibPrice, setShibPrice] = useState(0);
  const [rollingPrice, setRollingPrice] = useState(0.05);
  const [sftPrice, setSftPrice] = useState(14999);
  const [currencyPrice, setCurrencyPrice] = useState(14999);
  const [isResume, setIsResume] = useState(false);
  let refCode = window.localStorage.getItem("refCode");
  const [loading, setLoading] = useState(false);
  const [paused, setPaused] = useState(false);
  const [receivedNftItems, setReceivedNftItems] = useState([]);
  const vidRef = useRef(null);
  const [activeNft, setActiveNft] = useState({});

  const currentCurrency = useMemo(
    () => storeCurrencies.find((u) => u.text.toLowerCase() === currency?.toLowerCase()),
    [currency]
  );

  useEffect(async () => {
    let refCode = await window.localStorage.getItem("refCode");
    try {
      const [,ethers,signer] = await initProvider();
      const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
      console.log(storeContract);
      let latestPrice = await storeContract.rollingPrice();
      setRollingPrice(ethers.utils.formatUnits(latestPrice.toString(), 18));
      try {
        let referCode = await storeContract.getAffiliateInfor();
        console.log(referCode);
        setReferrerCode((referCode?.referrer && referCode.referrer > 0) ? referCode.referrer : refCode);
      } catch (error) {
        console.log(error);
        setReferrerCode(refCode);
      }
      let sftAddress = await storeContract.sftAddress();
      console.log(sftAddress);
      setSftAddress(sftAddress);

      let sftTmpPrice = await storeContract.getLatestPrice(latestPrice.toString(), sftAddress);
      setSftPrice(ethers.utils.formatUnits(sftTmpPrice.toString(), 9));
      setCurrencyPrice(ethers.utils.formatUnits(sftTmpPrice.toString(), 9))

      let shibAddress = await storeContract.shibAddress();
      console.log(shibAddress);
      setShibAddress(shibAddress);
      let shibTmpPrice = await storeContract.getLatestPrice(latestPrice.toString(), shibAddress);
      console.log(shibTmpPrice);
      setShibPrice(ethers.utils.formatUnits(shibTmpPrice.toString(), 9));
    } catch (error) {
      console.log(error);
      setReferrerCode(refCode);
    }
  }, [account]);

  useEffect(async () => {
    try {
      const [,ethers,signer] = await initProvider();
      const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
      let latestPrice = new BigNumber(parseFloat(rollingPrice) * 1000000000).multipliedBy(1000000000).multipliedBy(quantity).toString();
      if (currency === "SFT") {
        let sftAddressTmp = await storeContract.sftAddress();
        console.log(sftAddressTmp);
        setSftAddress(sftAddressTmp);
        let sftTmpPrice = await storeContract.getLatestPrice(latestPrice, sftAddressTmp);
        sftTmpPrice = new BigNumber(sftTmpPrice.toString()).multipliedBy(0.8);
        setCurrencyPrice(ethers.utils.formatUnits(sftTmpPrice.toString(), 9));
      } else {
        if (currency === "SHIB") {
          let shibAddressTmp = await storeContract.shibAddress();
          console.log(shibAddressTmp);
          setSftAddress(shibAddressTmp);
          let shibTmpPrice = await storeContract.getLatestPrice(latestPrice, shibAddressTmp);
          console.log(shibTmpPrice.toString());
          setCurrencyPrice(ethers.utils.formatUnits(shibTmpPrice.toString(), 18));
        } else {
          setCurrencyPrice(ethers.utils.formatUnits(latestPrice.toString(), 18));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [currency, quantity, account]);

  const endpoint = useMemo(
    () => "/public/houses",
    []
  );

  const { data: nftAssets, nftAssetsError } = useAuthenticatedSwr(endpoint, {}, apiMarketUrl);

  useEffect(() => {
    if (nftAssets) {
      const itemsSlider = $(".items-slider");
      itemsSlider.owlCarousel({
        loop: true,
        center: true,
        navText: ["<img class=\"prev-btn\" src=\"/assets/images/arrow-left.png\"/>", "<img class=\"next-btn\" src=\"/assets/images/arrow-right.png\"/>"],
        nav: true,
        dots: false,
        autoplay: false,
        margin: 30,
        autoplayTimeout: 6000,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 3
          },
          500: {
            items: 3
          },
          768: {
            items: 3
          },
          992: {
            items: 5
          },
          1200: {
            items: 5
          },
          1920: {
            items: 5
          }
        }
      });

      itemsSlider.on('changed.owl.carousel', (e) => {
        setTimeout(() => {
          let nftId = window.$('.owl-item.active.center .slider-item').attr('id');
          let isHouse = window.$('.owl-item.active.center .slider-item').attr('isHouse');
          let nftInfo = nftItemFor(nftId);
          if (nftInfo) {
            setActiveNft({...nftInfo, ...{isHouse: isHouse}});
          }
        }, 1500);
      });
    }
  }, [nftAssets]);

  const nftItemFor = useCallback(
    (id) => {
      let nftItems = [];
      if (nftAssets) {
        nftItems = [...nftAssets?.houses, ...nftAssets?.pets];
      }
      console.log(nftItems);
      return (nftItems || []).find((item) => item._decimal.toString() === id.toString());
    },
    [nftAssets]
  );

  async function initProvider(){
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    return [provider,ethers,signer];
  }

  const buyEvent = useCallback(async (event) => {
    if (event) {
      event.preventDefault();
    }
    const [provider,ethers,signer] = await initProvider();
    const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
    const sftContract = new ethers.Contract(sftAddress, contractConfig.shfContract.abi, signer);
    const shibContract = new ethers.Contract(shibAddress, contractConfig.shfContract.abi, signer);
    try {
      if (!account) {
        window.$("#js-walletPopup").modal("show");
        return true;
      }

      if (!account || loading) {
        return true;
      }

      const petContract = new ethers.Contract(contractConfig.petContract.address, contractConfig.petContract.abi, signer);
      let nftIds = await petContract.getAllNFTID();
      let accounts = Array.from({length: 25}, () => {
        return account;
      });
      let firstPets = await petContract.balanceOfBatch(accounts, nftIds);
      console.log(firstPets);

      setLoading(true);
      if (currency === "ETH") {
        let latestPrice = await storeContract.rollingPrice();
        latestPrice = new BigNumber(latestPrice.toString()).multipliedBy(quantity);
        const gasLimit = await storeContract.estimateGas.roll(new BigNumber(quantity).toString(), "0x0000000000000000000000000000000000000000", referrerCode ? referrerCode : (refCode ? refCode : "0"),{ value: latestPrice.toString()});
        console.log(new BigNumber(quantity).toString(), "0x0000000000000000000000000000000000000000", referrerCode ? referrerCode : (refCode ? refCode : "0"), { value: latestPrice.toString() });
        let transaction = await storeContract.roll(new BigNumber(quantity).toString(), "0x0000000000000000000000000000000000000000", referrerCode ? referrerCode : (refCode ? refCode : "0"),
          { value: latestPrice.toString(), gasLimit: parseInt((parseFloat(gasLimit.toString()) * 1.3).toString()) });
        await provider.waitForTransaction(transaction.hash);
      } else {
        if (currency === "SFT") {
          let latestPrice = await storeContract.rollingPrice();
          latestPrice = new BigNumber(latestPrice.toString()).multipliedBy(quantity);
          latestPrice = await storeContract.getLatestPrice(latestPrice.toString(), sftAddress);
          latestPrice = new BigNumber(latestPrice.toString()).multipliedBy(0.8);
          let sftBalance = await sftContract.balanceOf(await signer.getAddress());
          sftBalance = new BigNumber(sftBalance.toString());
          if (sftBalance.isLessThan(latestPrice)) {
            toast("Insufficient SFT!");
            setLoading(false);
            return false;
          }

          const approve = await sftContract.approve(contractConfig.storeContract.address, latestPrice.toString());
          const transactionData = await provider.waitForTransaction(approve.hash);
          console.log(transactionData);
          console.log("approved");

          const gasLimit = await storeContract.estimateGas.roll(new BigNumber(quantity).toString(), sftAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));
          console.log(new BigNumber(quantity).toString(), sftAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));
          console.log(gasLimit.toString());
          const buyData = await storeContract.roll(new BigNumber(quantity).toString(), sftAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"),
            { gasLimit: parseInt((parseFloat(gasLimit.toString()) * 1.3).toString()) });
          console.log("Buying...");

          await provider.waitForTransaction(buyData.hash);
        } else {
          let latestPrice = await storeContract.rollingPrice();
          latestPrice = new BigNumber(latestPrice.toString()).multipliedBy(quantity);
          latestPrice = await storeContract.getLatestPrice(latestPrice.toString(), shibAddress);
          
          let shibBalance = await shibContract.balanceOf(await signer.getAddress());
          shibBalance = new BigNumber(shibBalance.toString());
          if (shibBalance.isLessThan(latestPrice)) {
            toast("Insufficient SHIB!");
            setLoading(false);
            return false;
          }

          const approve = await shibContract.approve(contractConfig.storeContract.address, latestPrice.toString());
          const transactionData = await provider.waitForTransaction(approve.hash);
          console.log(transactionData);
          console.log("approved");
          const gasLimit = await storeContract.estimateGas.roll(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));

          console.log(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));
          const buyData = await storeContract.roll(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"),
            { gasLimit: parseInt((parseFloat(gasLimit.toString()) * 1.3).toString()) });
          console.log("Buying...");

          await provider.waitForTransaction(buyData.hash);
        }
      }
      let nftsTmp = [];
      let lastPets = await petContract.balanceOfBatch(accounts, nftIds);
      console.log(lastPets);
      lastPets.forEach((numberOfTokens, index) => {
        let boughtPets = parseInt(numberOfTokens.toString()) - parseInt(firstPets[index].toString());
        if (parseInt(numberOfTokens.toString()) > 0 && boughtPets > 0) {
          for (var i=1;i <= boughtPets; i++) {
            nftsTmp.push(nftItemFor(nftIds[index]?.toString()));
          }
        }
      });

      console.log(nftsTmp);

      setReceivedNftItems(nftsTmp);
      setIsResume(false);
      vidRef.current.play();
      setLoading(false);
      toast("Bought Success!");
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [loading, currency, account, refCode, referrerCode, sftAddress, shibAddress, quantity, nftAssets]);

  const buyWithShibEvent = useCallback(async (event) => {
    event.preventDefault();
    const [provider,ethers,signer] = await initProvider();
    const storeContract = new ethers.Contract(contractConfig.storeContract.address, contractConfig.storeContract.abi, signer);
    const shibContract = new ethers.Contract(shibAddress, contractConfig.shfContract.abi, signer);
    try {
      if (!account || loading) {
        return true;
      }
      setLoading(true);
      const petContract = new ethers.Contract(contractConfig.petContract.address, contractConfig.petContract.abi, signer);
      let nftIds = await petContract.getAllNFTID();
      let accounts = Array.from({length: 25}, () => {
        return account;
      });
      let firstPets = await petContract.balanceOfBatch(accounts, nftIds);

      let latestPrice = await storeContract.rollingPrice();
      latestPrice = new BigNumber(latestPrice.toString()).multipliedBy(quantity);
      latestPrice = await storeContract.getLatestPrice(latestPrice.toString(), shibAddress);
      
      let shibBalance = await shibContract.balanceOf(await signer.getAddress());
      shibBalance = new BigNumber(shibBalance.toString());
      if (shibBalance.isLessThan(latestPrice)) {
        toast("Insufficient SHIB!");
        setLoading(false);
        return false;
      }

      const approve = await shibContract.approve(contractConfig.storeContract.address, latestPrice.toString());
      const transactionData = await provider.waitForTransaction(approve.hash);
      console.log(transactionData);
      console.log("approved");

      const gasLimit = await storeContract.estimateGas.roll(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));

      console.log(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"));
      const buyData = await storeContract.roll(new BigNumber(quantity).toString(), shibAddress, referrerCode ? referrerCode : (refCode ? refCode : "0"),
        { gasLimit: parseInt((parseFloat(gasLimit.toString()) * 1.3).toString()) });
      console.log("Buying...");

      await provider.waitForTransaction(buyData.hash);

      let nftsTmp = [];
      let lastPets = await petContract.balanceOfBatch(accounts, nftIds);
      lastPets.forEach((numberOfTokens, index) => {
        let boughtPets = parseInt(numberOfTokens.toString()) - parseInt(firstPets[index].toString());
        if (parseInt(numberOfTokens.toString()) > 0 && boughtPets > 0) {
          for (var i=1;i <= boughtPets; i++) {
            nftsTmp.push(nftItemFor(nftIds[index]?.toString()));
          }
        }
      });

      setReceivedNftItems(nftsTmp);
      setIsResume(false);
      vidRef.current.play();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [loading, account, refCode, referrerCode, sftAddress, shibAddress, quantity, nftAssets]);

  return (
    <>
      <div className="hero-area hero-area2 hero-text hero-area1 c-nftInfo__section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="content">
                <div className="video-content">
                  <video playsInline width="100%" height={isMobile ? 300 : 400} ref={vidRef} onEnded={(e) => {
                    setIsResume(true);
                    setPaused(true);
                    window.$("#js-rollResultPopup").modal("show");
                  }} paused={paused}>
                    <source src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/ShibaUnbox.mp4" type="video/mp4" />
                  </video>
                  {isResume && 
                    <div className="resume-placeholder">
                      <div className="resume-content">
                        <div className="blue-text">
                          Your MYSTERY BOX Purchase <br />
                          is AVAILABLE in NFT Assets
                        </div>
                        <div className="btns">
                          <a href="/nft-game-assets" className="yellow-btn">Go to NFT ASSETS</a>
                          <a className="pink-btn" onClick={(e) => {
                            e.preventDefault();
                            vidRef.current.currentTime = 0;
                            setIsResume(false);
                          }}>RESUME</a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="content">
                <div className="row">
                  <div className="col-lg-8 col-8">
                    <img src="/assets/images/shiba-inu-text.png" />
                  </div>
                  <div className="col-lg-4 col-4">
                    <a onClick={buyWithShibEvent}><img src="/assets/images/shib-button.png" /></a>
                  </div>
                </div>
                <div className="buy-box">
                  <img src="/assets/images/mystery-box.png" className="buy-box-head"/>
                  <div className="referer-box">Referer: {referrerCode?.toString() !== "0" ? referrerCode : ""}</div>
                  <div className="row custom-row">
                    <div className="col-lg-4 col-4 custom-col">
                      <div className="blue-text buy-box-label">Quantity</div>
                      <a onClick={(e) => {
                        if (quantity > 1) {
                          setQuantity(quantity - 1);
                        }
                      }}><span className="blue-text buy-box-minus">-</span></a>
                      <input type="text" className="buy-box-input" value={quantity} readOnly/>
                      <a onClick={(e) => {
                        if (quantity < 10) {
                          setQuantity(quantity + 1);
                        }
                      }}><span className="blue-text buy-box-plus">+</span></a>
                    </div>
                    <div className="col-lg-4 col-4 custom-col">
                      <div className="yellow-text eth-price">{rollingPrice} ETH <img src="/assets/images/eth-icon.png" className="eth-icon"/><br />per box</div>
                      <div className="yellow-text number-tokens">{currencyPrice}</div>
                    </div>
                    <div className="col-lg-4 col-4 custom-col">
                      <div className="blue-text buy-box-label">Buy with</div>
                      <div className="custom-dropdown dropdown show">
                        <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={currentCurrency.imageUrl} className={currentCurrency.text === "ETH" ? "coin-icon eth-coin" : "coin-icon"}/>{currentCurrency ? (currentCurrency?.text === "SFT" ? <span>SFT <span className="discount-text">(-20%)</span></span> : currentCurrency?.text) : <span>SFT <span className="discount-text">(-20%)</span></span>}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          {storeCurrencies.map((currencyOpt, index) =>
                            <a className="dropdown-item" key={index} onClick={(e) => {
                              e.preventDefault();
                              setCurrency(currencyOpt.text);
                            }
                            }><img src={currencyOpt.imageUrl} className={currencyOpt.text === "ETH" ? "coin-icon eth-coin" : "coin-icon"}/>{currencyOpt.text === "SFT" ? <span>SFT <span className="discount-text">(-20%)</span></span> : <span>{currencyOpt.text}</span>}</a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row custom-row">
                    <div className="col-lg-4 col-4 custom-col">
                    </div>
                    <div className="col-lg-8 col-8 custom-col">
                      <a className="buy-btn" onClick={buyEvent} disabled={loading}>{loading ? "BUYING..." : "BUY"}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row season-row">
            <div className="season-one-shiba-head">
              <img src="/assets/images/SEASON-ONE-SHIBA.png" alt="ShibafriendNFT"/>
            </div>
            <div className="season-slider">
              <div className="items-slider">
                {nftAssets?.pets?.filter((item) => !['Shiba Swimmer', 'Shiba Referee', 'Shiba Valentine', 'Shiba Chinese New Year', 'Shiba Vietnam Tet', 'Shiba Solider', 'Shiba Commando'].includes(item.name)).map((item, index) => 
                  <div className="slider-item" id={item._decimal.toString()} isHouse={false} key={index} onClick={(event) => {
                    event.preventDefault();
                    setActiveNft({...item, ...{isHouse: false}});
                  }}>
                    <model-viewer class="marketplace-viewer slider-item-viewer" src={item.animation_url} camera-controls shadow-intensity="1">
                      <div className="lazy-load-poster" slot="poster">Loading...</div>
                    </model-viewer>
                  </div>
                )}
                {nftAssets?.houses?.map((item, index) => 
                  <div className="slider-item" key={index} id={item._decimal.toString()} isHouse={true} onClick={(event) => {
                    event.preventDefault();
                    setActiveNft({...item, ...{isHouse: true}});
                  }}>
                    <model-viewer class="marketplace-viewer slider-item-viewer" src={item.animation_url} camera-controls shadow-intensity="1">
                      <div className="lazy-load-poster" slot="poster">Loading...</div>
                    </model-viewer>
                  </div>
                )}
                <div className="slider-item">
                  <div>
                    <img src="/assets/images/eth-icon.png" />
                    <div className="blue-text">$1000 ETH</div>
                  </div>
                </div>
                <div className="slider-item">
                  <div>
                    <img src="/assets/images/sft-animation.gif" />
                    <div className="blue-text">$100 SFT</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="platform-center">
              <img src="/assets/images/platform.png" alt="ShibafriendNFT"/>
            </div>
          </div>
        </div>
        <RollResultPopup nftItems={receivedNftItems}/>
      </div>
      <NFTDetail nftInfo={activeNft?.name ? activeNft : (nftAssets?.pets ? nftAssets?.pets[0] : {})}/>
    </>
  );
}

export default ClawMachine;
