import React from "react";
import { ToastContainer } from "react-toastify";

import HeaderForDashboard from "../components/HeaderForDashboard";
import MyAccount from "../components/MyAccount";
import WalletListPopup from "../components/WalletListPopup";

function MyAccountPage(props) {
  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"my-account"}/>
      <MyAccount />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default MyAccountPage;
