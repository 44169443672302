import React, {useState, useEffect, useMemo} from "react";
import {useParams} from "react-router";
import { ToastContainer } from "react-toastify";
import "../../assets/css/marketplace.css";

import useFetchOption, { apiMarketUrl } from "../../hooks/useFetchOption";
import useAuthenticatedSwr from "../../hooks/useAuthenticatedSwr";

import HeaderForDashboard from "../../components/HeaderForDashboard";
import NftGameAssetDetail from "../../components/GameAssets/Detail";
import WalletListPopup from "../../components/WalletListPopup";

function GameAssetDetail(props) {
  const { tokenId } = useParams("");
  
  const endpoint = useMemo(
    () => `/public/epet/${tokenId}`,
    []
  );

  const { data: nftItem, error } = useAuthenticatedSwr(endpoint, {}, apiMarketUrl);

  return (
    <div>
      <HeaderForDashboard currentRoute={"nft-game-assets"} {...props}/>
      <NftGameAssetDetail nftItem={nftItem} tokenId={tokenId}/>
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default GameAssetDetail;
