import React, {useState, useCallback} from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {isMobile} from "react-device-detect";
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import { apiMarketUrl } from "../../hooks/useFetchOption";
import { contractConfig } from "../../config/contract";
import SellPopup from "./SellPopup";
import { gameToken } from "../../config/global";
import NftDetail from "./NftDetail";

function GameAssetDetail({ nftItem, tokenId }) {
  const accessToken = window.localStorage.getItem(gameToken);
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(nftItem?.default);
  const [loadingOnGame, setLoadingOnGame] = useState(false);

  function formatAccount(address) {
    return address?.slice(0, 10) + "..." + address?.slice(address.length - 12, address.length);
  }

  const attributes = useCallback(
    (attrName) => {
      return (nftItem?.attributes || []).find((item) => item.trait_type.toString() === attrName)?.value;
    },
    [nftItem, nftItem?.attributes]
  );

  const sellEvent = useCallback(async (event) => {
    try {
      event.preventDefault();
      if (!account || loading) {
        return true;
      }
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      if (nftItem?.owner.toLowerCase() !== contractConfig.marketContract.address.toLowerCase()) {
        window.$("#js-sellPopup").modal("show");
        return false;
      }

      setLoading(true);

      const contract = new ethers.Contract(contractConfig.marketContract.address, contractConfig.marketContract.abi, signer);

      const cancelData = await contract.cancelTrading(contractConfig.petContract.address, nftItem.token_id);

      await provider.waitForTransaction(cancelData.hash);

      toast("Cancelled!");
      setLoading(false);
    } catch (error) {
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [account, loading, nftItem?.owner, nftItem?.token_id]);

  const setDefaultOnGame = useCallback(() => {
    console.log(account, accessToken);
    if (account && accessToken && !loadingOnGame) {
      setLoadingOnGame(true);
      axios.put(`${apiMarketUrl}/game/pet/default`, { token_id: nftItem?.token_id }, { headers: { Authorization: `Bearer ${accessToken}`}}).then((response) => {
        console.log(response);
        toast("Selected!");
        setIsDefault(true);
        setLoadingOnGame(false);
      }).catch((error) => {
        console.log(error);
        toast("Can not select!");
        setLoadingOnGame(false);
      });
    }
    return false;
  }, [nftItem?.token_id, account, accessToken, loadingOnGame]);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="row c-filter c-filter__left">
                              <a href="/nft-game-assets"><i className="fas fa-chevron-left" /> Back</a>
                            </div>
                            <NftDetail nftItem={nftItem} isDefault={isDefault}/>
                          </div>
                          <div className="row col-lg-9 plan-list">
                            <div className="row c-nftItem__detail">
                              <div className="c-nftItem__head">ABOUT</div>
                              <div className="c-nftItem__property">
                                <div className="title">Description</div>
                                <div className="value"><span className="value">{nftItem?.description}</span></div>
                              </div>
                              {attributes("gender") && <div className="c-nftItem__property">
                                <div className="title">GENDER</div>
                                <div className="title batch-name">{attributes("gender")}</div>
                              </div>}
                              {attributes("skills") && <div className="c-nftItem__property">
                                <div className="title">SKILLS</div>
                                <div className="value">{attributes("skills")}</div>
                              </div>}
                              {attributes("life") && <div className="c-nftItem__property">
                                <div className="title">LIFE: <span className="value">{attributes("life")}</span></div>
                              </div>}
                              <div className="c-nftItem__property">
                                <div className="title">NFT ID: <span className="value">{isMobile ? formatAccount(tokenId) : tokenId}</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellPopup nftItem={nftItem} />
    </section>
  );
}

export default GameAssetDetail;
