import React from "react";
import { useNavigate } from "react-router-dom";
import "@google/model-viewer";

function NftItem({ nftItem }) {
  const navigate = useNavigate();
  console.log(nftItem);

  return (
    <div className="col-lg-4 c-nftItem" onClick={() => navigate(`/nft-game-assets/${nftItem._decimal}`)}>
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem.name}</div>
        <div className="c-nftItem__image">
          <model-viewer class="marketplace-viewer" src={nftItem?.animation_url} camera-controls shadow-intensity="1">
            <div className="lazy-load-poster" slot="poster">Loading...</div>
          </model-viewer>
        </div>
        <div className="c-nftItem__batchName">
          {nftItem?.skills}
        </div>
        <div className="c-nftItem__tier">
          {nftItem?.gender?.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export default NftItem;
