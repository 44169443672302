
export async function fetchWithConfig(url, config = {}) {
  try {
    const res = await fetch(url, config);

    if (!res.ok) {
      const error = new Error("Error while fetching data.");
      const info = await res.json();
      error.message = info?.error;
      throw error;
    }

    return await res.json();
  } catch (error) {
    throw new Error(error.toString());
  }
}

export const removeEmpty = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));

export const parameterize = (str) =>
  str.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
