const sortTypes = [
  { orderBy: "id", orderType: "asc", value: "lowest_id", text: "Lowest ID" },
  { orderBy: "id", orderType: "desc", value: "highest_id", text: "Highest ID" },
  { orderBy: "price", orderType: "asc", value: "lowest_price", text: "Lowest Price" },
  { orderBy: "price", orderType: "desc", value: "highest_price", text: "Highest Price" },
  { orderBy: "created_at", orderType: "desc", value: "latest", text: "Latest" },
];

const gameToken = "gameToken";
const username = "username";
const prevAccount = "prevAccount";
const signatureKey = "signatureKey";

export { sortTypes, gameToken, username, prevAccount, signatureKey };
