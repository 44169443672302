import React, {useState, useEffect, useCallback} from "react";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import { ethers } from "ethers";

import { contractConfig } from "../config/contract";
import { toast } from "react-toastify";
const busdABI = require("../config/BUSDABI.json");

function SalePlan(props) {
  const { plan, investor, success } = props;
  const availableAmount = plan.totalPool - plan.soldAmount;
  const [amount, setAmount] = useState(investor ? investor.amount : availableAmount / 1000000000);
  const [currency, setCurrency] = useState("BNB")
  const [isStake, setIsStake] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { account }= useWeb3React();

  async function buyEvent(event) {
    event.preventDefault();
    if (!investor || investor.status !== "whitelisted" || isLoading) {
      return false;
    }
    if (plan.minAmount > 0 && (amount < plan.minAmount || amount > plan.maxAmount)) {
      success("Amount is invalid!");
      return false;
    }
    let amountWithDecimal = new BigNumber(amount.toString());
    let amountFinal = (currency === "BUSD") ? amountWithDecimal.multipliedBy(1000000000000000000).toString() : amountWithDecimal.multipliedBy(1000000).toString();
    setIsLoading(true);
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    if (currency === "BNB") {
      const airdropContract = new ethers.Contract(contractConfig.airdropContract.address, contractConfig.airdropContract.abi, signer);
      let latestPrice = await airdropContract.getLatestPrice();
      latestPrice = new BigNumber(latestPrice.toString());
      let airdropPrice = await airdropContract.airdropPrice();
      latestPrice = latestPrice.dividedBy(parseInt(airdropPrice)).multipliedBy(parseInt(amount));
      let bnbBalance = await provider.getBalance(await signer.getAddress());
      bnbBalance = new BigNumber(bnbBalance.toString());

      if (bnbBalance.isLessThan(latestPrice)) {
        toast("Insufficient BNB");
        setIsLoading(false);
        return false;
      }

      amountFinal = latestPrice.toString();
    }

    console.log(amountFinal);

    try {
      let response = await smartContract(provider, ethers).transfer(ownerAddress(), amountFinal);
      provider.waitForTransaction(response.hash).then((transactionData) => {
        axios.put(`${process.env.REACT_APP_API_URL}/api/investors/${investor.id}`, {transactionHash: transactionData.transactionHash, transactionData: transactionData, amount: amount, chainId: contractConfig.chainID, currency: currency}, { headers: {"X-Api-Key": process.env.REACT_APP_API_KEY} }).then((response) => {
          setIsLoading(false);
          success("Payment Success!")
        }).catch((error) => {
          console.log(error);
          success("Payment Failed! Please try again!");
          setIsLoading(false);
        });
      });
    } catch (ex) {
      console.log(ex);
      success("Payment Failed! Please try again!");
      setIsLoading(false);
    }
  }

  const smartContract = useCallback((provider, ethers) => {
    if (currency === "BUSD") {
      if (contractConfig.chainID === "56" || contractConfig.chainID === "97") {
        return new ethers.Contract(process.env.REACT_APP_BUSD_ADDRESS, busdABI, provider.getSigner(account));
      } else {
        return new ethers.Contract(process.env.REACT_APP_ETH_BUSD_ADDRESS, busdABI, provider.getSigner(account));
      }
    } else {
      if (currency === "USDT") {
        if (contractConfig.chainID === "56" || contractConfig.chainID === "97") {
          return new ethers.Contract(process.env.REACT_APP_USDT_ADDRESS, busdABI, provider.getSigner(account));
        } else {
          return new ethers.Contract(process.env.REACT_APP_ETH_USDT_ADDRESS, busdABI, provider.getSigner(account));
        }
      } else {
        if (contractConfig.chainID === "56" || contractConfig.chainID === "97") {
          return new ethers.Contract(process.env.REACT_APP_BNB_ADDRESS, busdABI, provider.getSigner(account));
        } else {
          return new ethers.Contract(process.env.REACT_APP_ETH_BNB_ADDRESS, busdABI, provider.getSigner(account));
        }
      }
    }
  }, [contractConfig, currency, account]);

  const ownerAddress = useCallback(() => {
    if (currency === "BUSD" || currency === "BNB") {
      if (contractConfig.chainID === "56" || contractConfig.chainID === "97") {
        return process.env.REACT_APP_BUSD_OWNER_ADDRESS;
      } else {
        return process.env.REACT_APP_ETH_BUSD_OWNER_ADDRESS;
      }
    } else {
      if (contractConfig.chainID === "56") {
        return process.env.REACT_APP_USDT_OWNER_ADDRESS;
      } else {
        return process.env.REACT_APP_ETH_USDT_OWNER_ADDRESS;
      }
    }
  }, [contractConfig, currency]);

  useEffect(() => {
    setAmount(investor?.amount);
  }, [investor]);

  useEffect(() => {
    if(plan.maxAmount > 0 && amount > plan.maxAmount) {
      setAmount(plan.maxAmount);
    }

    if(plan.minAmount > 0 && amount > 0 && amount < plan.minAmount) {
      setAmount(plan.minAmount);
    }
  }, [amount, plan]);

  function handleChange(event) {
    setAmount(event.target.value);
  }

  return (
    <div className="row stake-plan-item">
      <div className="col-lg-2"><img alt="" src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon" /></div>
      <div className="col-lg-10">
        <div className="stake-plan-head">1 SFT = ${plan.price}</div>
        {!isStake && <div className="">
          <div className="stake-plan-label stake-plan-label-presale">WHITELISTED: <span className={investor?.id ? "status active" : "status inactive"}>{investor?.id ? "Yes" : "No"}</span></div>
          <div className="stake-plan-label stake-plan-label-presale">WALLET ENABLED: <span className={account ? "status active" : "status inactive"}>{account ? "Yes" : "No"}</span></div>
        </div>}
        <div className="stake-plan-form row">
          <div className="col-lg-6">
            <div className="stake-plan-label-presale">
              <div className="blue-text label-control no-shadow">Buy with ({currency})</div>
            </div>
            <div className="">
              <input
                type="number"
                name="amount"
                className="amount-input"
                disabled={investor?.id && (investor.status !== "whitelisted" || investor.amount > 0)}
                value={amount}
                min={investor.amount ? investor.amount : plan.minAmount}
                max={investor.amount ? investor.amount : plan.maxAmount}
                onChange={handleChange}/>
            </div>
            <div className="stake-plan-label-presale">{numeral(amount / plan.price).format("0,0.0000")} SFT</div>
          </div>
        </div>
        <div className="stake-plan-btn">
          {account ? <a className="custom-btn-v3" onClick={buyEvent} disabled={(!investor?.id || investor.status !== "whitelisted" || isLoading) ? "disabled" : ""}>{!investor?.id ? "NOT WHITELISTED" : (investor.status === "whitelisted" ? (isLoading ? "LOADING..." : "BUY") : investor.status)}</a> : <a className="mybtn1" onClick={(event) => {
            event.preventDefault();
            window.$("#js-walletPopup").modal("show");
          }}>CONNECT WALLET</a>}
        </div>
      </div>
    </div>
  );
}

export default SalePlan;
