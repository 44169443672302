import React, {useState, useCallback} from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BigNumber from "bignumber.js";
import { contractConfig } from "../../config/contract";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import NftDetail from "./NftDetail";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

function NftStoreDetail({nftItem, isLoading}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  let refCode = window.localStorage.getItem("refCode");
  const [isBought] = useState(false);
  const [currency, setCurrency] = useState("BNB");
  const busdABI = require("../../config/BUSDABI.json");
  const { account } = useWeb3React();
  const buyEvent = useCallback(async (event) => {
    event.preventDefault();
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner();
    const storeContract = new ethers.Contract(contractConfig.storeContractBNB.address, contractConfig.storeContractBNB.abi, signer);
    const shfAffiliateContract = new ethers.Contract(contractConfig.shfAffiliateContract.address, contractConfig.shfAffiliateContract.abi, signer);
    let referrerCode = await shfAffiliateContract.getReferrerCode();
    if (!referrerCode) {
      referrerCode = refCode; 
    }
    try {
      if (!account || loading) {
        return true;
      }
      setLoading(true);

      if (currency !== "BNB") {
        const busdContract = new ethers.Contract(contractConfig.busdContract.address, busdABI, signer);
        let busdBalance = await busdContract.balanceOf(await signer.getAddress());
        busdBalance = new BigNumber(busdBalance.toString());
        console.log(busdBalance.toString());
        let price = new BigNumber(nftItem?.price);
        price = price.multipliedBy(1000000000000000000);
        if (busdBalance.isLessThan(price)) {
          toast("Insufficient BUSD!");
          setLoading(false);
          return false;
        }
        const approve = await busdContract.approve(contractConfig.storeContract.address, price.toString());

        if (!approve.hash) {
          setLoading(false);
          toast("Can not approve this transaction. please retry again later!");
        } else {

          const transactionData = await provider.waitForTransaction(approve.hash);
          console.log(transactionData);
          console.log("approved");

          const buyData = await storeContract.buyWithAffiliate(contractConfig.petContractBNB.address, nftItem.id, contractConfig.busdContract.address, referrerCode ? referrerCode : "0");
          console.log("Buying...");

          await provider.waitForTransaction(buyData.hash);

          toast("Bought!");
          setLoading(false);
          navigate("/nft-game-assets");
        }
      } else {
        const balance = await provider.getBalance("0x0000000000000000000000000000000000000000");
        let latestPrice = await storeContract.getLatestPrice(nftItem?.price);
        latestPrice = new BigNumber(latestPrice.toString());
        console.log(latestPrice.toString());
        let bnbBalance = new BigNumber(balance?.balance?.toString());
        console.log(balance);

        if (bnbBalance.isLessThan(latestPrice)) {
          toast("Insufficient BNB!");
          setLoading(false);
          return false;
        }
        console.log("startbuy...", referrerCode);
        const buyDataBNB = await storeContract.buyWithAffiliate(contractConfig.petContractBNB.address, nftItem.id, "0x0000000000000000000000000000000000000000", referrerCode ? referrerCode : "0", { value: latestPrice.toString() });
        console.log("Buying...");

        await provider.waitForTransaction(buyDataBNB.hash);
        toast("Bought!");
        setLoading(false);
        navigate("/nft-game-assets");
      }
    } catch (error) {
      console.log(error);
      toast(error?.message.toString());
      setLoading(false);
    }
  }, [nftItem, loading, busdABI, currency,account, refCode]);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="row c-filter c-filter__left">
                              <a href="/marketplace"><i className="fas fa-chevron-left" /> Back</a>
                            </div>
                            {isLoading ? <div className="not-found">Loading...</div> : <NftDetail nftItem={nftItem} />}
                          </div>
                          <div className="row col-lg-9 plan-list c-nftItemDetail">
                            {nftItem?.price && !isBought &&
                              <div className="row c-filter center">
                                <div className="blue-text label-control">Buy with</div>
                                {/* <div className="mybtn1 switch-item">
                                  <img src="/img/busd.png"
                                    alt=""
                                    className={currency === "BUSD" ? "selected": ""}
                                    onClick={() => setCurrency("BUSD")}/>
                                  <img src="/img/BNB-icon.png" alt=""
                                    className={`is-right ${currency === "BNB" ? "selected": ""}`}
                                    onClick={() => setCurrency("BNB")}/>
                                </div> */}
                                <div className="buy-now-actions">
                                  <a className="mybtn1 btn-buy-now btn-custom" onClick={buyEvent}>
                                    {loading ? "Loading..." : `BUY with ${currency}!`}
                                  </a>
                                </div>
                              </div>
                            }
                            <div className="row c-nftItem__detail">
                              <div className="c-nftItem__head">ABOUT</div>
                              <div className="c-nftItem__property">
                                <div className="title">TYPE</div>
                                <div className="value">{nftItem?.tier?.name}</div>
                              </div>
                              <div className="c-nftItem__property">
                                <div className="title batch-name">{nftItem?.design?.name}</div>
                              </div>
                              <div className="c-nftItem__property">
                                <div className="title">OWNER</div>
                                <div className="value"> </div>
                              </div>
                              <div className="c-nftItem__property">
                                <div className="title">TOKEN ID: <span className="value">Token ID will be generated upon successful payment and minting of NFT</span></div>
                              </div>
                            </div>
                            {nftItem?.price && !isBought &&
                              <div className="row c-filter center">
                                <div className="blue-text label-control label-control-last">Buy with</div>
                                {/* <div className="mybtn1 switch-item">
                                  <img src="/img/busd.png"
                                    alt=""
                                    className={currency === "BUSD" ? "selected": ""}
                                    onClick={() => setCurrency("BUSD")}/>
                                  <img src="/img/BNB-icon.png" alt=""
                                    className={`is-right ${currency === "BNB" ? "selected": ""}`}
                                    onClick={() => setCurrency("BNB")}/>
                                </div> */}
                                <div className="buy-now-actions">
                                  <a className="mybtn1 btn-buy-now btn-custom" onClick={buyEvent}>
                                    {loading ? "Loading..." : `BUY with ${currency}!`}
                                  </a>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NftStoreDetail;
