import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useWeb3React } from "@web3-react/core";

import useAuth from "../hooks/useAuth";
import {
  NavLink,
} from "react-router-dom";

function Header(props) {
  const { t, i18n } = useTranslation();
  const { currentRoute } = props;
  const { account } = useWeb3React();
  const { signout } = useAuth();
  
  // useEffect(() => {
  //   const bscChainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
  //   if (account && chainId && bscChainId !== chainId) {
  //     switchNetwork(bscChainId);
  //   }
  // }, [account, chainId]);
  
  function formatAccount() {
    return account?.slice(0, 5) + "..." + account?.slice(account?.length - 4, account?.length);
  }

  return (
    <header className="header">
      <section className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content">
                <div className="left-content">
                  <ul className="left-list">
                    <li>
                      <p>
                        <i className="fas fa-headset"></i>	Support
                      </p>
                    </li>
                  </ul>
                  <ul className="top-social-links">
                    <li>
                      <a href="https://www.facebook.com/shibafriendcoin" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/shibafriendcoin" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/Shibafriend_official" target="_blank" rel="noreferrer">
                        <i className="fab fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/shibafriend/" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/shibafriendcoin/" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCCkkSb5kRFTiIHYuNdYFUKw" target="_blank" rel="noreferrer" >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tiktok.com/@shibafriend" target="_blank" rel="noreferrer" >
                        <img src="/img/tiktok.png" alt="" className="social-icon"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.com/invite/TYnC5W4qqt" target="_blank" rel="noreferrer" >
                        <img src="/img/discord.png" alt="" className="social-icon"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://coinmarketcap.com/currencies/shibafriend" target="_blank" rel="noreferrer" >
                        <img src="/img/CMC.png" alt="" className="social-icon"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://shibafriend.medium.com" target="_blank" rel="noreferrer" >
                        <img src="/img/medium.png" alt="" className="social-icon"/>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="right-content">
                  <ul className="right-list">
                    <li>
                      <div className="language-selector">
                        <select name="language" className="language" onChange={(event) => {
                          document.cookie = `locale=${event.target.value}`;
                          i18n.changeLanguage(event.target.value);
                        }}>
                          <option value="en" selected={i18n.options.lng === "en"}>EN</option>
                          <option value="vi" selected={i18n.options.lng === "vi"}>VI</option>
                          <option value="cn" selected={i18n.options.lng === "cn"}>CN</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div className="notofication"  data-toggle="modal" data-target="#usernotification">
                        <i className="far fa-bell"></i>
                      </div>
                    </li>
                    <li>
                      <div className="message" data-toggle="modal" data-target="#usermessage">
                        <a href="mailto:marketing@shibafriend.io"><i className="far fa-envelope"></i></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mainmenu-area mainmenu-area2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">                 
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand d-lg-none" href="index.html">
                  <img className="l2" src="/assets/images/logo.png" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse fixed-height" id="main_menu">
                  <div className="main-menu-inner">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                        <a className={`nav-link ${currentRoute === "home" ? "active" : ""}`} href="/">
                          {t("header.home")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/sftstaking" className={`nav-link ${currentRoute === "sftstaking" ? "active" : ""}`}>SFT STAKING</NavLink>
                      </li>
                      <li className="nav-item">
                        <a href="https://opensea.io/collection/shibafriend-nft" className={`nav-link ${currentRoute === "marketplace" ? "active" : ""}`}>MARKETPLACE</a>
                      </li>
                      <li className="nav-item">
                        <a href="https://wiki.shibafriend.io" className={`nav-link ${currentRoute === "token-pool" ? "active" : ""}`}>WHITEPAPER</a>
                      </li>
                      
                    </ul>
                    <a className="navbar-brand  d-none d-lg-block" href="#">
                      <img className="l2" src="/assets/images/logo.png" alt="" />
                    </a>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a className="nav-link" href="https://gameplay.shibafriend.io/" target="_blank" rel="noreferrer">GAME GUIDE</a>
                      </li>
                      <li className="nav-item">
                        <a href="/play-game" className={`nav-link ${currentRoute === "play-game" ? "active" : ""}`}>PLAY GAME</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/sft/PreIDO">
                          BUY SFT
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/buycrypto">
                          BUY CRYPTO
                        </a>
                      </li>
                      {account ? <li className="usermenu-item nav-item">
                        <span className="tag"></span>
                        <a href="#" className="custom-btn-v3 custom-btn-v4 connect-wallet" title={account}><i className="fas fa-cog" /> {formatAccount()} <i className="fas fa-caret-down"></i></a>
                        <div className="dp-menu">
                          <ul className="dp-links">
                            <li>
                              <a href="/my-account">
                                <i className="far fa-user-circle"></i>My Account
                              </a>
                            </li>
                            <li>
                              <a href="#" title={account} onClick={(event) => {
                                event.preventDefault();
                                signout();
                              }}><i className="fas fa-sign-out-alt"></i> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      : <li className="nav-item">
                        <span className="tag"></span>
                        <a className="connect-wallet custom-btn-v3" href="#" onClick={(event) => {
                          event.preventDefault();
                          window.$("#js-walletPopup").modal("show");
                        }} title={account}>
                          <span>Connect Wallet</span>
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                  
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </header>
  );
}

export default Header;
