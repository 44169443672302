import { useCallback } from "react"

import { connectorLocalStorageKey } from "../components/wallet/Config"
import { gameToken, username, prevAccount, signatureKey } from "../config/global"
import { apiMarketUrl } from "../hooks/useFetchOption";
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import { connectorsByName } from "../utils/web3React";

const useAuth = () => {
  const { library, chainId, activate, deactivate, account } = useWeb3React();

  const login = useCallback(async (connectorID) => {
    const accessToken = await window.localStorage.getItem(gameToken);
    const signKey = await window.localStorage.getItem(signatureKey);
    const connector = connectorsByName[connectorID];

    if(!account){
      if (connector) {
        console.log("connector:", connector);
        console.log("ISMobile:", isMobile);
        if(isMobile){
          console.log("ON MOBILE");
          const res = await activate(connector);
          console.log(res);
          console.log(accessToken);
          if (!accessToken) {
            const provider = new ethers.providers.Web3Provider(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL));
            console.log(provider);
            console.log(provider.getSigner(account));
            const signature = signKey ? signKey : await provider.getSigner(account).signMessage("Hello Shibafriend NFT");
            console.log("SIGNATURE:", signature)
            if(signature){
              window.localStorage.setItem(signatureKey, signature);
              await axios.post(`${apiMarketUrl}/public/game/login_moralis`, { token: signature }).then((token) => {
                console.log(token);
                window.localStorage.setItem(gameToken, token?.data?.data?.access_token);
                window.localStorage.setItem(username, token?.data?.data?.user?.username)
              });
            }
          }

        }else{
          console.log("ON DESKTOP");
          const res = await activate(connector);
          if (!accessToken) {
            const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
            const signature = signKey ? signKey : await provider.getSigner(account).signMessage("Hello Shibafriend NFT");
            console.log(signature);
            console.log(provider.getSigner(account));
            console.log(provider);
            if(signature){
              window.localStorage.setItem(signatureKey, signature);
              await axios.post(`${apiMarketUrl}/public/game/login_moralis`, { token: signature }).then((token) => {
                console.log(token);
                window.localStorage.setItem(gameToken, token?.data?.data?.access_token);
                window.localStorage.setItem(username, token?.data?.data?.user?.username)
              });
            }
          }
        }
      }
    } else{
      if (!accessToken) {
        await axios.post(`${apiMarketUrl}/public/game/login_moralis`, { token: signKey }).then((token) => {
          console.log("received token",token);
          window.localStorage.setItem(gameToken, token?.data?.data?.access_token);
          window.localStorage.setItem(username, token?.data?.data?.user?.username)
        });
      }
    }

  }, [account, chainId, library]);

  const signout = useCallback(() => {
    // console.log("logout",user);
    // logout();
    // This localStorage key is set by @web3-react/walletconnect-connector

    window.localStorage.removeItem(connectorLocalStorageKey);
    window.localStorage.removeItem(gameToken);
    window.localStorage.removeItem(username);
    window.localStorage.removeItem(prevAccount);
    window.localStorage.removeItem(signatureKey);
    if (account) { deactivate(); }
  }, [account ,deactivate])

  return { login, signout}
}
export default useAuth
