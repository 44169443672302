import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import {useParams} from "react-router";
import {
  useNavigate
} from "react-router-dom";

import Header from "../components/Header";
import ClawMachine from "../components/Home/ClawMachine";
import SubscribeSection from "../components/SubscribeSection";
import Footer from "../components/Footer";
import WalletListPopup from "../components/WalletListPopup";
import PrivacyPolicyPopup from "../components/PrivacyPolicyPopup";
import TermsOfServicePopup from "../components/TermsOfServicePopup";
import NFTInformation from "../components/Home/NFTInformation";
import ShibaNFTPet from "../components/Home/ShibaNFTPet";
import ShibaFootball from "../components/Home/ShibaFootball";
import FeaturedIn from "../components/Home/FeaturedIn";
import WinPrizes from "../components/Home/WinPrizes";

function Home(props) {
  let {defaultWidth, height, width, i18n, web3} = props;
  const { account } = useWeb3React();
  const { refCode } = useParams("");
  const { affCode } = useParams("");
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/privacy-policy") {
      window.$("#js-privacyPolicyPopup").modal("show");
    }

    if (window.location.pathname === "/terms-of-service") {
      window.$("#js-termsOfServicePopup").modal("show");
    }

    if (refCode) {
      window.localStorage.setItem("refCode", refCode);
    }

    if (refCode && !account) {
      window.$("#js-walletPopup").modal("show");
    }

    if (affCode) {
      window.localStorage.setItem("shfRefCode", affCode);
      navigate("/sft");
    }
  }, [refCode, affCode, account])

  return (
    <div>
      <Header height={height} width={width} ratio={width/defaultWidth} i18n={i18n} currentRoute={"home"} web3={web3} {...props}/>
      <ClawMachine height={height} width={width} ratio={width/defaultWidth} i18n={i18n}/>
      <WinPrizes height={height} width={width} ratio={width/defaultWidth} i18n={i18n}/>
      <NFTInformation height={height} width={width} ratio={width/defaultWidth} i18n={i18n}/>
      <ShibaNFTPet height={height} width={width} ratio={width/defaultWidth} i18n={i18n}/>
      <FeaturedIn height={height} width={width} ratio={width/defaultWidth} i18n={i18n}/>
      <SubscribeSection />
      <Footer />
      <WalletListPopup />
      <PrivacyPolicyPopup />
      <TermsOfServicePopup />
      <ToastContainer />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
    </div>
  );
}



export default Home;
