export const currencies = [
  { address: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee", text: "BUSD", imageUrl: "/assets/images/currencies/busd.png", decimals: 1000000000000000000 },
  // { address: '0x25a446a574e86f73040FF18900197f6e4f9D636D', text: 'SHF', imageUrl: '/assets/images/currencies/shf.png', decimals: 1000000000 },
  { address: "0x0000000000000000000000000000000000000000", text: "BNB", imageUrl: "/assets/images/currencies/bnb.png", decimals: 1000000000000000000 }
];

export const storeCurrencies = [
  { address: "0x01B6D39746ccE500B70cfB242dCC5F7596b11e41", text: "SFT", imageUrl: "/assets/images/sft-coin.png", decimals: 1000000000000000000 },
  { address: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce", text: "SHIB", imageUrl: "/assets/images/shib-logo.png", decimals: 1000000000 },
  { address: "0x0000000000000000000000000000000000000000", text: "ETH", imageUrl: "/assets/images/eth-logo.png", decimals: 1000000000000000000 }
];
