import React, {useState} from "react";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { isMobile } from "react-device-detect";
import Web3 from "web3";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import { contractConfig } from "../config/contract";
import { toast } from "react-toastify";

function StakePlan(props) {
  const { account } = useWeb3React();
  const { plan, planId, currentBalance } = props;
  const availableAmount = plan.maximumAmount - plan.stakedAmount;
  const [amount, setAmount] = useState(plan.minimumAmount / 1000000000);
  const [isStake, setIsStake] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  
  async function stakeEvent(event) {
    event.preventDefault();
    const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
    const signer = provider.getSigner(account);
    if (isLoading) {
      return false;
    }
    if (isStake) {
      try {
        let bamount = amount * 1000000000;
        setIsLoading(true);
        const tokenContract = new ethers.Contract(contractConfig.shfContract.address, contractConfig.shfContract.abi, signer);
        const stakeContract = new ethers.Contract(contractConfig.shfStakingContract.address, contractConfig.shfStakingContract.abi, signer);
        const response = await tokenContract.approve(contractConfig.shfStakingContract.address, bamount);
        await provider.waitForTransaction(response.hash);
        const res = await stakeContract.stake(planId, bamount);
        await provider.waitForTransaction(res.hash);
        setIsStake(false);
        setIsLoading(false);
       
        toast("Stake Success!");
      } catch (ex) {
        console.log(ex);
      }
    } else {
      setIsStake(true);
    }
  }

  async function setMax(event) {
    event.preventDefault();
    setAmount(maxAvailableAmount() / 1000000000);
  }

  function maxAvailableAmount() {
    if (availableAmount >= currentBalance * 1000000000) {
      return currentBalance * 1000000000;
    }

    return availableAmount;
  }

  function handleChange(event) {
    let { value, min, max } = event.target;

    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    setAmount(value);
  }

  function convertMinutesToMonth(numberOfMinutes) {

    const units = {
      "year": 24 * 60 * 365,
      "month": 24 * 60 * 30,
      "week": 24 * 60 * 7,
      "day": 24 * 60,
      "minute": 1
    }

    let result = []

    for(var name in units) {
      var p =  Math.floor(numberOfMinutes/units[name]);
      if(p == 1) result.push(p + " " + name);
      if(p >= 2) result.push(p + " " + name + "s");
      numberOfMinutes %= units[name]
    }
    return result.join(" ");
  }

  if (plan?.status === 0) {
    return null;
  }

  return (
    <div className="row stake-plan-item">
      <div className="col-lg-3"><img src="/img/shibacoin-gold2-1050.png" className="stake-plan-icon" /></div>
      <div className="col-lg-9">
        <div className="stake-plan-head">STAKE SFT {convertMinutesToMonth(parseInt(plan?.period?.toString()))}</div>
        {!isStake && <div className="">
          <div className="stake-plan-label">APR {plan?.rate?.toString()}%</div>
          <div className="stake-plan-label">Lock Period {convertMinutesToMonth(parseInt(plan?.period?.toString()))}</div>
        </div>}
        {isStake && <div className="stake-plan-form row">
          <div className="col-lg-5">
            <div className="">Amount</div>
            <div className="">
              <input
                type="number"
                name="amount"
                className="amount-input"
                value={amount}
                min={new BigNumber(plan?.minimumAmount?.toString()).dividedBy(1000000000).toString()}
                max={new BigNumber(maxAvailableAmount()).dividedBy(1000000000).toString()}
                onChange={handleChange}/>
              <a href="#" className="mybtn1 btn-max" onClick={setMax}>Max</a>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="">Min: {numeral(ethers.utils.formatUnits(plan.minimumAmount, 9)).format("0,0")} SFT</div>
            <div className="">Total Pool: {numeral(ethers.utils.formatUnits(plan.maximumAmount, 9)).format("0,0")} SFT</div>
            <div className="">Available: {numeral(ethers.utils.formatUnits(availableAmount, 9)).format("0,0")} SFT</div>
            <div className="">Lock Period: {convertMinutesToMonth(parseInt(plan?.period?.toString()))}</div>
          </div>
        </div>}
        <div className="stake-plan-btn">
          {(currentBalance < plan.minimumAmount / 1000000000) ?
            <a>NO BALANCE</a>
            : 
            (plan.status === 1 && availableAmount > 0 && availableAmount > plan.minimumAmount / 1000000000) ? <a href="#" className="mybtn1 stake-btn" onClick={stakeEvent}>{isLoading ? "LOADING..." :  "STAKE"}</a> : <a className="">ENDED</a>
          }
        </div>
      </div>
    </div>
  );
}

export default StakePlan;
