import React, {useState} from "react";
import { ToastContainer } from "react-toastify";

import HeaderForDashboard from "../components/HeaderForDashboard";
import Overview from "../components/Overview";
import WalletListPopup from "../components/WalletListPopup";

function Dashboard(props) {
  return (
    <div>
      <HeaderForDashboard currentRoute={"dashboard"} {...props}/>
      <Overview />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default Dashboard;
