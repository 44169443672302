import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import MyAccountPage from "./pages/MyAccount";
import MysteryBox from "./pages/MysteryBox";
import MarketplaceDetail from "./pages/Marketplace/Detail";
import NftStore from "./pages/NftStore";
import NftStoreDetail from "./pages/NftStore/Detail";
import GameAssets from "./pages/GameAssets";
import NftGameAssetDetail from "./pages/GameAssets/Detail";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import TokenPool from "./pages/TokenPool";
import TokenPage from "./pages/TokenPage";
import PlayGamePage from "./pages/PlayGamePage";
import BuyCryptoPage from "./pages/BuyCryptoPage";
import Sale from "./pages/Sale";
import PrivateSale from "./pages/PrivateSale";
import PreIdo from "./pages/PreIdo";
import AirdropDiamond from "./pages/AirdropDiamond";
import useEagerConnect from "./hooks/useEagerConnect";

function App(props) {
  const {i18n} = props;
  const DEFAULT_HEIGHT = window.innerWidth <= 768 ? 1280 : 1080;
  const DEFAULT_WIDTH = 1920;
  var height = DEFAULT_HEIGHT/DEFAULT_WIDTH * window.innerWidth;
  const [size, setSize] = useState([window.innerWidth, height]);
  window.addEventListener("resize", function () {
    setSize([window.innerWidth, DEFAULT_HEIGHT/DEFAULT_WIDTH * window.innerWidth]);
  });

  function childProps() {
    return {
      height: size[1],
      width: size[0],
      ratio: size[0]/DEFAULT_WIDTH,
      i18n: i18n,
      ...props
    };
  }

  useEagerConnect()

  return (
    <Router>
      <Routes>

        <Route path="/play-game" element={
          <PlayGamePage {...childProps()}/>
        } />

        <Route path="/buycrypto" element={
          <BuyCryptoPage {...childProps()}/>
        } />

        <Route path="/token" element={
          <TokenPage {...childProps()}/>
        } />
        <Route path="/dashboard" element={
          <Dashboard {...childProps()}/>
        } />

        <Route path="/sftstaking" element={
          <TokenPool {...childProps()}/>
        } />
        <Route path="/token-pool" element={
          <TokenPool {...childProps()}/>
        } />
        <Route path="/privacy-policy" element={
          <Home {...childProps()}/>
        } />
        <Route path="/terms-of-service" element={
          <Home {...childProps()}/>
        } />
        <Route path="/nft-game-assets" element={
          <GameAssets {...childProps()}/>
        } />
        <Route path="/my-account" element={
          <MyAccountPage {...childProps()}/>
        } />
        <Route name="marketplace" path="/nft-game-assets/:tokenId" element={
          <NftGameAssetDetail {...childProps()}/>
        } />
        <Route name="marketplace" path="/marketplace/nft/:tokenId" element={
          <MarketplaceDetail {...childProps()}/>
        } />
        <Route path="/marketplace" element={
          <NftStore {...childProps()}/>
        } />
        <Route name="marketplace" path="/marketplace/:handle" element={
          <NftStoreDetail {...childProps()}/>
        } />
        <Route path="/mystery-box" element={
          <MysteryBox {...childProps()}/>
        } />
        <Route name="home" path="/affiliate/:refCode" element={
          <Home {...childProps()}/>
        } />
        <Route name="home" path="/sftcoin/:affCode" element={
          <Home {...childProps()}/>
        } />
        <Route path="/" element={
          <Home {...childProps()}/>
        } />
        <Route path="/diamondairdrop" element={
          <AirdropDiamond {...childProps()}/>
        } />
        <Route path="/sft" element={
          <PrivateSale {...childProps()}/>
        } />
        <Route path="/sft/PreIDO" element={
          <PreIdo {...childProps()}/>
        } />
        <Route path="/sft/IDO" element={
          <PreIdo {...childProps()}/>
        } />
        <Route name="presale" path="/:handle" element={
          <Sale {...childProps()}/>
        } />
      </Routes>
    </Router>
  );
}

export default React.memo(App);
