import React, {useState, useEffect, useCallback} from "react";
import numeral from "numeral";
import "react-toastify/dist/ReactToastify.css";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import Web3 from "web3";

import { contractConfig } from "../../config/contract";
import "@google/model-viewer";

function AirdropDiamond() {
  const pricePerDiamond = 0.00001;
  const { account, chainId } = useWeb3React();
  const [inAirdrop, setInAirdrop] = useState(false);
  const [airdropAmount, setAirdropAmount] = useState(5000000);
  const [airdropPrice, setAirdropPrice] = useState(20);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [isBought, setIsBought] = useState(false);
  const bscChainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

  const claimEvent = useCallback(async (event) => {
    event.preventDefault();
    try {
      if (loading || isBought || !inAirdrop) {
        return true;
      }

      setLoading(true);
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      const airdropContract = new ethers.Contract(contractConfig.airdropContract.address, contractConfig.airdropContract.abi, signer);
      let latestPrice = await airdropContract.getLatestPrice();
      latestPrice = new BigNumber(latestPrice.toString());
      let bnbBalance = await provider.getBalance(await signer.getAddress());
      bnbBalance = new BigNumber(bnbBalance.toString());

      if (bnbBalance.isLessThan(latestPrice)) {
        toast("Insufficient BNB");
        setLoading(false);
        return false;
      }

      let response = await airdropContract.airdrop({value: latestPrice.toString()});
      console.log(response);
      console.log("Waiting...");

      await provider.waitForTransaction(response.hash);

      toast("Claimed!");
      setLoading(false);
      setIsBought(true);
    } catch (error) {
      console.log(error);
      toast(error?.data?.message.toString());
      setLoading(false);
    }
  }, [loading, account, isBought, inAirdrop])

  useEffect(async () => {
    setLoadingData(true);
    try {
      setInAirdrop(false);
      const provider = new ethers.providers.Web3Provider(isMobile ? new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_API_URL) : window.web3.currentProvider);
      const signer = provider.getSigner(account);
      const airdropContract = new ethers.Contract(contractConfig.airdropContract.address, contractConfig.airdropContract.abi, signer);
      setIsBought(await airdropContract.boughtAirdrop(await signer.getAddress()));
      setInAirdrop(await airdropContract.inAirdrop());
      setAirdropAmount(await airdropContract.airdropAmount());
      setAirdropPrice(await airdropContract.airdropPrice());
      setLoadingData(false);
      setIsError(false);
    } catch (error) {
      console.log(error);
      setLoadingData(false);
      setIsError(true);
    }
  },[account, chainId]);

  return (
    <section className="user-main-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="main">
              <div className="main-box wallet-box">
                <div className="header-area">
                  <h4>AIRDROP DIAMOND</h4>
                </div>
                <div className="wallet-tab-content"  id="pills-tabContent">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-wt1" role="tabpanel" aria-labelledby="pills-wt1-tab">
                      <div className="dipo-box">
                        <div className="row">
                          <div className="col-lg-3">
                          </div>
                          <div className="col-lg-9 plan-list">
                            <div className="row stake-plan-item">
                              <div className="col-lg-3"><img alt="" src="/assets/images/Shibafriend-Diamond.png" className="stake-plan-icon" /></div>
                              <div className="col-lg-9">
                                <div className="stake-plan-head"><h3>AIRDROP DIAMOND</h3></div>
                                {loadingData ? "Loading..." :
                                  (account ?
                                    <>
                                      <div className="">Total Diamond: <span className="blue-text no-shadow">{numeral(airdropAmount).format("0,0")}</span></div>
                                      <div className="">Available to Claim: <span className="blue-text no-shadow">{(!inAirdrop || isBought) ? "NO" : "YES"}</span></div>
                                      <div className="">1 Diamond = ${numeral(pricePerDiamond).format("0,0.00000")}</div>
                                      <div className="">${numeral(parseFloat(pricePerDiamond) * parseFloat(airdropAmount)).format("0,0")} Airdrops = {numeral(airdropAmount).format("0,0")} Diamonds</div>
                                      <div className="stake-plan-btn">
                                        {<a className="custom-btn-v2" onClick={claimEvent} disabled={!inAirdrop || isBought}>{inAirdrop ? (loading ? "Loading..." : (isBought ? "Claimed" : "Claim")) : ((!loadingData && chainId && bscChainId !== chainId) ? "Switch to BSC Network" : "Loading...")}</a>}
                                      </div>
                                    </> :
                                    <>
                                      <div className="">CONGRATULATIONS for completing the Airdrop!</div>
                                      <div className="">You are now WHITELISTED.</div>
                                      <div className="">Please connect your wallet and you will see your airdrop tokens in our dashboard.</div>
                                      <div className="">1 Diamond = ${numeral(pricePerDiamond).format("0,0.00000")}</div>
                                      <div className="">${numeral(pricePerDiamond * parseFloat(airdropAmount)).format("0,0")} Airdrops = {numeral(airdropAmount).format("0,0")} Diamonds</div>
                                      <div className="stake-plan-btn">
                                        {(inAirdrop || isError) ? <a className="custom-btn-v3" onClick={(event) => {
                                          event.preventDefault();
                                          window.$("#js-walletPopup").modal("show");}}>Connect wallet</a> :
                                          <a className="custom-btn-v3" disabled={!inAirdrop}>ENDED</a>}
                                      </div>
                                    </>)
                                }
                              </div>
                            </div>
                            <div className="row stake-plan-item">
                              <div className="col-lg-3">
                                {account && <model-viewer class="airdrop-viewer" src="https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/Shiba_Captain.glb" camera-controls shadow-intensity="1">
                                  <div className="lazy-load-poster" slot="poster">Loading...</div>
                                </model-viewer>}
                              </div>
                              <div className="col-lg-9">
                                {account ?
                                  <>
                                    <p><h3>CLAIM</h3></p>
                                    There will be a CLAIM SERVICE FEE of ${numeral(airdropPrice).format("0,0.00")}<br />
                                    In addition, you will receive a BRONZE Tier Shiba NFT (Shiba Captain) worth $50.00 by completing the Airdrop Diamond claim.<br />
                                    You can start play to earn Shiba music beat game immediately with your Shiba Captain.<br />
                                    One (1) Shibafriend SFT Token will be transferred to your wallet once the token has been listed. <br />
                                    Diamond Token is on Binance Smart Chain. How to add Binance Smart Chain to your metamask wallet. <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain</a> <br />
                                  </> :
                                  <>
                                    <p><h3>AIRDROP DIAMOND TOKEN RULES</h3></p>
                                    The token will be LOCKED for 90 days <br />
                                    Each wallet can only receive 1 AIRDROP<br />
                                    One (1) Shibafriend SFT Token will be transferred to your wallet once the token has been listed.<br />
                                    Diamond Token is on Binance Smart Chain. How to add Binance Smart Chain to your metamask wallet. <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain</a> <br />
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AirdropDiamond;
