import React, {useState} from "react";
import { ToastContainer } from "react-toastify";

import HeaderForDashboard from "../components/HeaderForDashboard";
import TokenPoolDetail from "../components/TokenPoolDetail";
import WalletListPopup from "../components/WalletListPopup";

function TokenPool(props) {
  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"token-pool"}/>
      <TokenPoolDetail />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default TokenPool;
