import React, {useState} from "react";
import { ToastContainer } from "react-toastify";
import "../../assets/css/marketplace.css";

import HeaderForDashboard from "../../components/HeaderForDashboard";
import GameAssetsIndex from "../../components/GameAssets";
import WalletListPopup from "../../components/WalletListPopup";

function GameAssets(props) {
  return (
    <div>
      <HeaderForDashboard currentRoute={"nft-game-assets"} {...props}/>
      <GameAssetsIndex />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <WalletListPopup />
      <ToastContainer />
    </div>
  );
}

export default GameAssets;
