import React from "react";

import UnclaimedItem from "./UnclaimedItem";

function UnclaimedPopup({ unclaimeds }) {
  return (
    <div className="modal fade popup" id="js-unclaimedPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="pink-text"></h4>
            <div className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body">
            {(unclaimeds || []).map((item, index) =>
              <UnclaimedItem key={index} unclaimedItem={item} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnclaimedPopup;
