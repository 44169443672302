import React from "react";

function FeaturedIn(props) {
  return (
    <section className="ex-lottery ex-lottery2 c-nftPet__section c-featuredIn__section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h5 className="subtitle">
                FEATURED IN
              </h5>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-cointelegraph.png" className="feature-logo"/>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-cmc.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-binance.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-market-watch.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-yahoo-news.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-yahoo-finance.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-digital-journal.png" className="feature-logo" />
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <img src="/assets/images/logo-benzinga.png" className="feature-logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedIn;
