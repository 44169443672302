import { useMemo } from "react";

const defaultOptions = {};

export const apiBaseUrl = process.env.REACT_APP_API_URL;
export const apiMarketUrl = process.env.REACT_APP_MARKET_API_URL;
export const s3Url = "https://shibafriend-assets.s3.ap-southeast-1.amazonaws.com/"

export default function useFetchOption(
  path = "",
  opts = defaultOptions,
  baseUrl = apiBaseUrl
) {

  const options = useMemo(
    () => ({
      ...opts
    }),
    [opts]
  );

  const fullPath = useMemo(() => `${baseUrl}${path}`, [baseUrl, path]);

  return { options, fullPath };
}
