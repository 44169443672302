import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import HeaderForDashboard from "../components/HeaderForDashboard";
import TokenSale from "../components/TokenSale";
import NotFound from "../components/NotFound";
import WalletListPopup from "../components/WalletListPopup";

function Sale(props) {
  const [salePlan, setSalePlan] = useState({});
  const { handle } = useParams("seed");

  useEffect(() => {
    getData();
  },[]);

  function getData() {
    axios.get(`${process.env.REACT_APP_API_URL}/api/token_sale_plans/${handle}`, { headers: {"X-Api-Key": process.env.REACT_APP_API_KEY} }).then((response) => {
      setSalePlan(response.data.data);
    }).catch(() => {
      setSalePlan(null);
    });
  }

  async function success(message) {
    await getData();
    toast(message);
  }

  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"token"}/>
      {salePlan ? <TokenSale salePlan={salePlan} success={(message) => success(message)}/> : <NotFound />}
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <WalletListPopup />
    </div>
  );
}

export default Sale;
