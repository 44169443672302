import React, {useMemo} from "react";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import "@google/model-viewer";
import { s3Url } from "hooks/useFetchOption";

function NftItem({ nftItem, buyEvent = () => {} }) {
  const navigate = useNavigate();
  const model_url = s3Url + nftItem?.design?.name?.replaceAll(" ", "_") + ".glb";

  return (
    <div className="col-lg-4 c-nftItem" onClick={() =>  navigate(`/marketplace/${nftItem?.handle}`)}>
      <div className="single-play">
        <div className="c-nftItem__name">{nftItem?.name}</div>
        <div className="c-nftItem__image">
          <model-viewer class="marketplace-viewer" src={model_url} camera-controls shadow-intensity="1">
            <div className="lazy-load-poster" slot="poster">Loading...</div>
          </model-viewer>
        </div>
        <div className="c-nftItem__batchName">
          {nftItem?.design?.name}
        </div>
        <div className="c-nftItem__tier">
          {nftItem?.tierInfo?.name}
        </div>
        {nftItem?.price &&
        <div className="row c-nftItem__buttons">
          <div className="c-nftItem__price col-6"><span className="">${numeral(nftItem?.price).format("0,0")}</span></div>
          <div className="c-nftItem__buyBtn col-6"><a href={`/marketplace/${nftItem?.handle}`} className="mybtn1 c-nftItem__btnBuy">Buy</a></div>
        </div>
        }
      </div>
    </div>
  );
}

export default NftItem;
