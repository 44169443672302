import React from "react";
import { ToastContainer } from "react-toastify";

import HeaderForDashboard from "../components/HeaderForDashboard";
import PreIdo from "../components/PreIdo";
import WalletListPopup from "../components/WalletListPopup";

function PrivateSalePage(props) {
  return (
    <div>
      <HeaderForDashboard {...props} currentRoute={"token"}/>
      <PreIdo />
      <div className="bottomtotop">
        <i className="fas fa-chevron-right"></i>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <WalletListPopup />
    </div>
  );
}

export default PrivateSalePage;
